import React, { useEffect, useState } from 'react';
import T from 'i18n';

type MappingSummaryProps = {
  failedRows: any;
  mappingData: any;
};

const MappingSummary = ({ failedRows, mappingData }: MappingSummaryProps) => {
  const [statement, setStatement] = useState<string>('');
  useEffect(() => {
    const mapDataSize = mappingData?.length;
    const notFoundInCSV = failedRows?.filter(
      (row: any) => row.type === 'error' && row.problemCategory === 'notFoundInCSV',
    )?.length;
    const orphanRow = failedRows?.filter(
      (row: any) => row.type === 'error' && row.problemCategory === 'orphanRow',
    )?.length;
    if ((mapDataSize && notFoundInCSV) || orphanRow) {
      setStatement(
        T.translate('case.batchUpload.dataMapper.summary', {
          mapDataSize,
          notFoundInCSV,
          orphanRow,
        }),
      );
    }
  }, [failedRows, mappingData]);
  return <p>{statement}</p>;
};

export default MappingSummary;
