/* eslint-disable @typescript-eslint/no-shadow */
import { selectPermissions } from 'common/selectors';
import { SelectForm } from 'features/common';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { findIndexOfDeepNestedFolder } from 'utils/arrays';
import T from 'i18n';

const MoveDocument = ({ control, errors, canUnselect, autofocus, readOnly, currentFolderType }) => {
  const state = useSelector(state => state);
  const [isTrialbundle, setIsTrialBundle] = useState(false);
  const {
    folders: { canAddDocuments },
  } = selectPermissions(state);
  const teamBundles = (state.case.folders.teamBundles || []).filter(folder =>
    canAddDocuments(folder),
  );
  const privateBundles = (state.case.folders.privateBundles || []).filter(folder =>
    canAddDocuments(folder),
  );
  const bundles = (state.case.folders.bundles || []).filter(folder => canAddDocuments(folder));
  const trialbooks = (state.case.folders.trialbooks || []).filter(folder =>
    canAddDocuments(folder),
  );
  const courtBundles = (state.case.folders.courtBundles || []).filter(folder =>
    canAddDocuments(folder),
  );

  const checkIsTrialBundle = (folders, id) => {
    setIsTrialBundle(false);
    const index = findIndexOfDeepNestedFolder(folders, { id });
    setIsTrialBundle(index !== -1);
  };

  const getDefaultValue = trialbooks => {
    if (trialbooks && trialbooks.length > 0) {
      const firstTrialbook = trialbooks[0] || {};
      if (
        firstTrialbook.name
          .toLowerCase()
          .startsWith(T.translate('case.defaultNameForMergedTrialbooks')) &&
        currentFolderType === 'trialbook'
      )
        return firstTrialbook.id || null;
    }
    return null;
  };

  const getBooks = books =>
    books.map(book => {
      if (book.folders && book.folders.length > 0) {
        return {
          label: book.name,
          options: getBooks(book.folders),
        };
      } else {
        return book;
      }
    });

  const options = [
    trialbooks.length > 0 && {
      label: 'Trialbooks',
      options: getBooks(trialbooks),
    },
    courtBundles.length > 0 && {
      label: 'Court bundles',
      options: getBooks(courtBundles),
    },
    teamBundles.length > 0 && {
      label: 'Team Bundles',
      options: getBooks(teamBundles),
    },
    privateBundles.length > 0 && {
      label: 'Private Bundles',
      options: getBooks(privateBundles),
    },
    bundles.length > 0 && {
      label: 'Bundles',
      options: getBooks(bundles),
    },
  ].filter(Boolean);

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <Form.Group>
        <SelectForm
          label={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginRight: '1rem',
              }}
            >
              <span>{T.translate('case.folderToMoveTo')}</span>
              {isTrialbundle && <span>{T.translate('case.moveToTriabundleWarning')}</span>}
            </div>
          }
          fullWidthLabel={isTrialbundle}
          placeholder={T.translate('case.folderPlaceholder')}
          name="folder"
          defaultValue={getDefaultValue(trialbooks)}
          valueKey="id"
          labelKey="name"
          options={options}
          control={control}
          rules={{ required: !canUnselect }}
          onChange={val => checkIsTrialBundle(trialbooks, val)}
          isClearable={canUnselect}
          readOnly={readOnly}
          errors={errors}
          autofocus={autofocus}
          menuPosition="fixed"
        />
      </Form.Group>
    </Form>
  );
};

export default MoveDocument;
