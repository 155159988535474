import { SHOW_FILTERED_ROWS } from './constants';
import initialState from './initialState';

export function setShowFilteredRows(showFiltered) {
  return { type: SHOW_FILTERED_ROWS, payload: { showFiltered } };
}
export function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_FILTERED_ROWS:
      return {
        ...state,
        displayDataMapFailedRows: action.payload.showFiltered,
      };

    default:
      return state;
  }
}
