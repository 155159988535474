import React, { useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import moment from 'moment';
import T from 'i18n';

type EditableCellProps = {
  value: string;
  onChange: (fieldId: string, newValue: string) => void;
  fieldId: string;
  isLastEditableField: boolean;
  focusNextRow: () => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  onEnter: () => void;
  invalidData: boolean;
  setInvalidData: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditableCell = ({
  value,
  onChange,
  fieldId,
  isLastEditableField,
  focusNextRow,
  inputRef,
  onEnter,
  setInvalidData,
}: EditableCellProps) => {
  const [localValue, setLocalValue] = useState(value ?? '');
  const [error, setError] = useState(false);

  const localInputRef = useRef<HTMLInputElement | null>(null);

  const combinedInputRef = (element: HTMLInputElement) => {
    if (localInputRef.current !== null) {
      localInputRef.current = element;
    }
    if (inputRef) {
      if (typeof inputRef === 'function') {
        (inputRef as (element: HTMLInputElement) => void)(element);
      } else {
        (inputRef as React.MutableRefObject<HTMLInputElement>).current = element;
      }
    }
  };

  useEffect(() => {
    setLocalValue(value ?? '');
  }, [value]);

  const formatDateInput = (val: string) => {
    const digits = val.replace(/\D/g, '');

    let formattedValue = '';
    if (digits.length >= 1) {
      formattedValue += digits.substring(0, 2);
    }
    if (digits.length >= 3) {
      formattedValue += '/' + digits.substring(2, 4);
    }
    if (digits.length >= 5) {
      formattedValue += '/' + digits.substring(4, 8);
    }
    return formattedValue;
  };

  const isValidDate = (val: string) => {
    if (val) {
      return moment(val, 'DD/MM/YYYY', true).isValid();
    } else {
      return true;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvalidData(false);
    setError(false);
    let newValue = e.target.value;

    if (fieldId === 'docDate') {
      newValue = formatDateInput(newValue);

      if (localInputRef.current) {
        const input = localInputRef.current;
        setTimeout(() => {
          input.setSelectionRange(newValue.length, newValue.length);
        }, 0);
      }
    }

    setLocalValue(newValue);
    onChange(fieldId, newValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (fieldId === 'docDate' && !isValidDate(localValue)) {
        setError(true);
        setInvalidData(true);
        return;
      } else {
        e.preventDefault();
        onEnter();
      }
    } else if (e.key === 'Tab') {
      if (isLastEditableField) {
        e.preventDefault();
        focusNextRow();
      }
    }
  };

  const handleBlur = () => {
    if (fieldId === 'docDate') {
      if (isValidDate(localValue)) {
        const formattedDate = moment(localValue, ['DD/MM/YYYY', 'DDMMYYYY']).format('DD/MM/YYYY');
        setLocalValue(formattedDate);
        onChange(fieldId, formattedDate);
      } else {
        setError(true);
        setInvalidData(true);
      }
    }
  };

  return (
    <>
      <TextField
        variant="standard"
        value={localValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        inputRef={combinedInputRef}
        size="small"
        error={fieldId === 'docDate' && error}
        helperText={fieldId === 'docDate' && error ? T.translate('generic.notAValidDate') : ''}
        style={{ display: 'flex' }}
        sx={{
          '& .MuiInput-underline:before': {
            borderBottomColor: '#e1e2e1', // Faint grey underline when not focused
          },
          '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: '#555555', // Slightly darker grey on hover
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#999999', // Underline color when focused
          },
        }}
      />
    </>
  );
};

export default EditableCell;
