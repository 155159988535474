import React, { useState, useEffect, forwardRef, useRef } from 'react';
import classnames from 'classnames';
import EditableCell from './EditableCell';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Fade } from '@mui/material';
import { WarningOutlined } from '@mui/icons-material';

type RowProps = {
  columns: Array<any>;
  dragHandleProps: any;
  row: any;
  sortMode: boolean;
  editMode: boolean;
  style: React.CSSProperties;
  isScrolling?: boolean;
  selected: boolean;
  index: number;
  hoverable?: boolean;
  selectedRowIds: Array<string>;
  focusNextRow: (currentRowIndex: number) => void;
  handleSaveChanges: (rowId: string, updatedData: any, row: any) => void;
  handleCancelChanges: (rowId: string) => void;
  firstEditableCellRef: React.RefObject<HTMLInputElement>;
  selectedRowIdsLength: number;
  onRowClick: (rowIndex: number) => void;
  currentlyEditingRowIndex: number | null;
  onRowSelected: (selectedRows: any[]) => any;
  mappedRowsWithErrors?: any;
};

const Row = forwardRef<HTMLTableRowElement, RowProps>(
  (
    {
      columns,
      dragHandleProps,
      row,
      sortMode,
      editMode,
      style,
      isScrolling,
      selected,
      index: rowIndex,
      hoverable,
      focusNextRow,
      handleSaveChanges,
      handleCancelChanges,
      firstEditableCellRef,
      selectedRowIdsLength,
      onRowClick,
      currentlyEditingRowIndex,
      mappedRowsWithErrors,
      ...rest
    },
    ref,
  ) => {
    const [hover, setHover] = useState(false);
    const [rowData, setRowData] = useState(row);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [showSavedIcon, setShowSavedIcon] = useState(false);
    const initialRowDataRef = useRef(row);
    const [invalidData, setInvalidData] = useState(false);
    const minWidth = columns.reduce(
      (acc, col) => acc + ((col.minWidth && parseInt(col.minWidth, 10)) || 0),
      0,
    );
    const handleFieldChange = (fieldId: string, newValue: string) => {
      setRowData((prevData: any) => ({
        ...prevData,
        [fieldId]: newValue,
      }));
      if (row[fieldId] !== newValue) {
        setHasUnsavedChanges(true);
      } else {
        const otherUnsaved = Object.keys(rowData).some(
          key => key !== fieldId && rowData[key] !== row[key],
        );
        setHasUnsavedChanges(otherUnsaved);
      }
    };

    const isOnlyOneRowSelected = selectedRowIdsLength === 1;

    const focusNextRowWrapper = () => {
      if (isOnlyOneRowSelected) {
        focusNextRow(rowIndex);
      }
    };

    let editableCellCounter = 0;
    const totalEditableCells = columns.filter(
      ({ id }) =>
        id !== 'tab' &&
        id !== 'globalPages' &&
        id !== 'id' &&
        id !== 'index' &&
        id !== 'type' &&
        id !== 'info' &&
        id !== 'recipient' &&
        id !== 'docDate' &&
        editMode,
    ).length;

    const handleConfirm = () => {
      if (invalidData) {
        return;
      } else {
        handleSaveChanges(row.id, rowData, row);
        setHasUnsavedChanges(false);
        setShowSavedIcon(true);
        setTimeout(() => {
          setShowSavedIcon(false);
        }, 1500);
      }
    };

    const handleCancel = () => {
      setRowData(initialRowDataRef.current);
      setHasUnsavedChanges(false);
    };

    const handleEnter = () => {
      handleConfirm();
    };

    const isCurrentlyEditing = currentlyEditingRowIndex && currentlyEditingRowIndex === rowIndex;

    useEffect(() => {
      if (!isCurrentlyEditing && hasUnsavedChanges) {
        handleConfirm();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCurrentlyEditing, hasUnsavedChanges]);

    useEffect(() => {
      initialRowDataRef.current = row;
      setRowData(row);
      setHasUnsavedChanges(false);
    }, [row.id]);

    let timeoutExit: NodeJS.Timeout, timeoutEnter: NodeJS.Timeout;

    return (
      <tr
        id={row.compositeKey || row.id}
        {...rest}
        style={{
          ...style,
          display: 'flex',
          minWidth,
          backgroundColor: mappedRowsWithErrors?.includes(row.name) ? '#ECD474' : undefined,
        }}
        ref={ref}
        onMouseEnter={() => {
          if (hoverable) {
            clearTimeout(timeoutExit);
            timeoutEnter = setTimeout(() => setHover(true), 50);
          }
        }}
        onMouseLeave={() => {
          clearTimeout(timeoutEnter);
          timeoutExit = setTimeout(() => setHover(false), 50);
        }}
      >
        {mappedRowsWithErrors?.includes(row.name) && selected && (
          <WarningOutlined
            style={{
              color: '#ECD474',
              position: 'absolute',
              left: 0,
              top: '14px',
              padding: '0.1px',
            }}
          />
        )}
        {columns.map(({ id, accessor, Cell, forwardRef, minWidth, width }, index) => {
          const isEditable =
            id === 'docDate' ||
            id === 'name' ||
            id === 'author' ||
            id === 'discoveryId' ||
            id === 'externalId';

          let inputRef;
          if (editableCellCounter === 0 && isEditable) {
            inputRef = firstEditableCellRef;
          }

          const isLastEditableField = isEditable && ++editableCellCounter === totalEditableCells;

          return (
            <td
              key={`${row.compositeKey || row.id}-${index}`}
              className={classnames(id || accessor, {
                'tab-align': id === 'tab',
              })}
              style={{
                minWidth,
                width,
                padding: '12px 8px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {isEditable && editMode ? (
                <EditableCell
                  value={rowData[id] ?? ''}
                  onChange={handleFieldChange}
                  fieldId={id}
                  isLastEditableField={isLastEditableField}
                  focusNextRow={focusNextRowWrapper}
                  inputRef={inputRef}
                  onEnter={handleEnter}
                  invalidData={invalidData}
                  setInvalidData={setInvalidData}
                />
              ) : Cell ? (
                forwardRef ? (
                  <Cell
                    dragHandleProps={dragHandleProps}
                    ref={dragHandleProps ? dragHandleProps.ref : null}
                    sortMode={sortMode}
                    isScrolling={isScrolling}
                    rowIndex={rowIndex}
                    rowHover={hover}
                    rowSelected={selected}
                    {...row}
                  />
                ) : (
                  <Cell
                    {...row}
                    sortMode={sortMode}
                    isScrolling={isScrolling}
                    rowIndex={rowIndex}
                    rowHover={hover}
                    rowSelected={selected}
                  />
                )
              ) : typeof accessor === 'function' ? (
                accessor(row)
              ) : (
                row[accessor]
              )}
            </td>
          );
        })}
        {hasUnsavedChanges && isCurrentlyEditing && (
          <td
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              padding: '0 8px',
              backgroundColor: '#6C757D',
            }}
          >
            <CheckIcon
              onClick={handleConfirm}
              style={{ cursor: 'pointer', marginRight: '8px', color: 'white' }}
            />
            <CloseIcon
              onClick={() => handleCancel()}
              style={{ cursor: 'pointer', color: 'white' }}
            />
          </td>
        )}
        {/* Save confirmation icon, above is the x and tick */}
        {showSavedIcon && (
          <Fade in={showSavedIcon} timeout={1000}>
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                padding: '0 8px',
              }}
            >
              <DoneIcon style={{ color: 'green' }} />
            </div>
          </Fade>
        )}
      </tr>
    );
  },
);

export default React.memo(Row);
