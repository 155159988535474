import React, { useEffect } from 'react';
import T from 'i18n';
import { ExpandMore, FilterListOutlined, Group, Info, KeyboardArrowUp } from '@mui/icons-material';
import { Avatar, Badge, Fab, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useFetchMatches } from '../redux/fetchMatches';
import SelectedMatchesContainer from './SelectedMatchesContainer';
import { useSelector } from 'react-redux';
import { selectParams } from 'common/selectors';
import SearchAnswerContainer from './SearchAnswerContainer';
import { SearchOptionsTooltip } from './SearchOptionsTooltip';
import { searchCenterLocation } from './enums';
import SearchMatchesDisplayContainer from './SearchMatchesDisplayContainer';
import { makeStyles } from '@mui/styles';
import SearchErrorPage from './SearchErrorPage';
import { useSetAISearchState } from '../redux/setAISearchState';
import DocSummary from './DocSummary';

const useStyles = makeStyles({
  answersContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem 1rem 1rem 0',
  },
  answersSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  answersSectionAvatar: {
    width: 36,
    height: 36,
  },
  answersSectionHeading: {
    color: '#0000007a',
    fontWeight: 600,
  },
  scrollToTopButton: {
    position: 'absolute',
    bottom: 0,
    right: '1rem',
    zIndex: 1000,
  },
});

const blinkAnimation = `@keyframes blinker {
  50% {
    opacity: 0;
  }
}`;

const searchingTextStyle = {
  animation: `blinker 1s linear infinite`,
  fontWeight: 600,
  color: '#0000007a',
};

interface SearchContainerProps {
  selectedMatches: Array<any>;
  scrollBarRef: any;
  searchContainerId: string;
  startNewSearch: Function;
  processQuestions: Function;
  askAIHandler: Function;
}

export default function SearchContainer({
  selectedMatches,
  scrollBarRef,
  searchContainerId,
  startNewSearch,
  processQuestions,
  askAIHandler,
}: SearchContainerProps) {
  const classes = useStyles();
  const { fetchDocumentsPending } = useFetchMatches();
  const { searchMatchesInput, searchAnsChat, searchSuggestedQuestions } = useSetAISearchState();
  const { file } = useSelector(selectParams);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [matchesMenuList, setMatchesMenuList] = React.useState<any>([]);
  const openAnswerMenu = Boolean(anchorEl);
  const [selectedContainerId, setSelectedContainerId] = React.useState<any>(null);
  const [isScrolled, setIsScrolled] = React.useState(false);
  const matchesData = searchMatchesInput?.find((itm: any) => itm.matches) || [];
  const showMatchesResp = searchMatchesInput.some((itm: any) => itm.showMatchesResp);
  const isError = matchesData && matchesData[0]?.isError;

  const handleMatchesMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    sources: Array<any>,
  ) => {
    const matchesIndex = sources
      .reduce((acc: any, src: any) => {
        const withoutDuplicates = src.sources.filter((itm: any) => !acc.includes(itm));
        return [...acc, ...withoutDuplicates];
      }, [])
      .sort((a: any, b: any) => a - b);
    const newMatchesList = matchesIndex
      .map((index: number) =>
        matchesData.matches[index - 1]
          ? {
              ...matchesData.matches[index - 1],
              srcIndex: index,
            }
          : null,
      )
      .filter((itm: any) => itm !== null);
    setMatchesMenuList(newMatchesList);
    setAnchorEl(event.currentTarget);
  };

  const handleMatchesLinkClicked = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number,
    allMatches: Array<any>,
    highlight: string,
  ) => {
    const match = {
      ...matchesData.matches[index - 1],
      srcIndex: index,
      highlight,
    };
    if (match) {
      setMatchesMenuList([match]);
      setAnchorEl(event.currentTarget);
    }
  };
  const handleMatchesMenuClose = (id: string) => {
    setSelectedContainerId(id || null);
    setMatchesMenuList([]);
    setAnchorEl(null);
  };

  const scrollToTop = () => {
    if (scrollBarRef.current) {
      scrollBarRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollBarRef.current.scrollTop > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const container = scrollBarRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollBarRef]);

  useEffect(() => {
    const parentContainer = scrollBarRef.current;
    if (parentContainer) {
      const targetElement = document.getElementById(selectedContainerId);
      if (targetElement) {
        const targetTop = targetElement.offsetTop;
        scrollBarRef.current.scrollTop = targetTop - 200;
      }
    }
  }, [scrollBarRef, file, selectedContainerId]);
  return (
    <Grid
      container
      height="100%"
      width="100%"
      sx={{ alignContent: 'end', position: 'relative' }}
      id="search-container"
      aria-label={searchContainerId}
    >
      <style>{blinkAnimation}</style>
      <PerfectScrollbar
        style={{ width: '100%' }}
        containerRef={ref => (scrollBarRef.current = ref)}
      >
        {isError && (
          <SearchErrorPage errorText={matchesData?.text} startNewSearch={startNewSearch} />
        )}
        {!isError &&
          showMatchesResp &&
          searchMatchesInput &&
          searchMatchesInput.length > 0 &&
          searchMatchesInput.map((md: any) => (
            <SearchMatchesDisplayContainer
              key={md.id}
              matchesData={md}
              fetchDocumentsPending={fetchDocumentsPending}
              startNewSearch={startNewSearch}
              selectedMatches={selectedMatches}
              setSelectedContainerId={setSelectedContainerId}
              suggestedQuestions={searchSuggestedQuestions}
              processQuestions={processQuestions}
              askAIHandler={askAIHandler}
            />
          ))}
        {searchAnsChat &&
          searchAnsChat.map((itm: any, index: number) => (
            <Grid item xs={12} key={index} id={itm.id}>
              <div
                className={classes.answersContainer}
                style={{
                  alignItems: itm.user === 'botIcon' ? 'start' : 'center',
                }}
              >
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    itm.contributor ? (
                      <Tooltip title={itm.contributor} placement="top" arrow>
                        <Avatar sx={{ width: 16, height: 16, bgcolor: 'white' }}>
                          <Group sx={{ color: 'GrayText' }} fontSize="small" />
                        </Avatar>
                      </Tooltip>
                    ) : null
                  }
                >
                  <Avatar
                    className={classes.answersSectionAvatar}
                    sx={{
                      ...(itm.user === 'botIcon' && { bgcolor: '#85A0F1' }),
                    }}
                  >
                    <Typography>
                      {itm.user === 'botIcon' ? T.translate('case.tvAvatarText') : itm.user}
                    </Typography>
                  </Avatar>
                </Badge>
                <div style={{ marginLeft: '1rem' }}>
                  <div className={classes.answersSection}>
                    <Typography
                      className={classes.answersSectionHeading}
                      variant={itm.user === 'botIcon' ? 'body1' : 'h5'}
                    >
                      {itm.user === 'botIcon' ? T.translate('case.trialview') : itm.text}
                    </Typography>
                    {itm.user !== 'botIcon' && itm.options && (
                      <Tooltip
                        title={<SearchOptionsTooltip options={itm.options} />}
                        placement="right"
                        arrow
                      >
                        <Info fontSize="small" sx={{ color: '#85A0F1', ml: '1rem' }} />
                      </Tooltip>
                    )}
                    {itm.user === 'botIcon' && itm.matches && typeof itm.text !== 'string' && (
                      <IconButton size="small" onClick={e => handleMatchesMenuOpen(e, itm.text)}>
                        <ExpandMore fontSize="small" />
                      </IconButton>
                    )}
                  </div>
                  {itm.user !== 'botIcon' &&
                    itm.location !== searchCenterLocation.allMatches &&
                    itm.location !== 'All Matches' && (
                      <div style={{ marginTop: '0.5rem' }}>
                        <FilterListOutlined fontSize="small" sx={{ mr: '0.5rem', mb: '0.2rem' }} />
                        <span>{itm.location}</span>
                      </div>
                    )}
                  {itm.user === 'botIcon' && (
                    <Typography
                      variant="h6"
                      style={{
                        lineBreak: 'auto',
                        ...(itm.text === T.translate('case.searchingText') && searchingTextStyle),
                      }}
                    >
                      <PerfectScrollbar style={{ height: '100%' }}>
                        {itm.command === 'summarize' ? (
                          <DocSummary
                            summary={itm.text}
                            submissions={itm.submissions}
                            rulings={itm.rulings}
                            scrollBarRef={scrollBarRef}
                          />
                        ) : typeof itm.text === 'string' ? (
                          itm.text
                        ) : (
                          <SearchAnswerContainer
                            response={itm.text}
                            typingEffect={itm.typingEffect}
                            scrollBarRef={scrollBarRef}
                            handleMatchesLinkClicked={handleMatchesLinkClicked}
                            allMatchesCount={matchesData?.matches?.length}
                          />
                        )}
                      </PerfectScrollbar>
                    </Typography>
                  )}
                </div>
              </div>
              {openAnswerMenu && anchorEl && (
                <SelectedMatchesContainer
                  matchesItm={matchesMenuList}
                  handleMatchesMenuClose={handleMatchesMenuClose}
                  anchorEl={anchorEl}
                  openAnswerMenu={openAnswerMenu}
                  parentContainerId={itm.id}
                />
              )}
            </Grid>
          ))}
      </PerfectScrollbar>
      {isScrolled && (
        <Fab
          color="primary"
          size="small"
          variant="extended"
          className={classes.scrollToTopButton}
          onClick={scrollToTop}
        >
          <KeyboardArrowUp />
        </Fab>
      )}
    </Grid>
  );
}
