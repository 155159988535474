import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

const MIN_DATE = moment(new Date(1800, 0, 1));
const MAX_DATE = moment(new Date(3000, 0, 1));

export const isValidDate = (val: any) => {
  if (!val) return false;

  const date = moment(val, 'DD/MM/YYYY', true);

  if (!date.isValid()) return false;

  if (date.year() < MIN_DATE.year()) return false;

  if (date.year() > MAX_DATE.year()) return false;

  return true;
};

export default ({ onChange, onBlur, value, placeholder, style, ...props }: any) => (
  <DatePicker
    {...props}
    format="dd/MM/yyyy"
    value={value}
    onChange={onChange}
    minDate={MIN_DATE.toDate()}
    maxDate={MAX_DATE.toDate()}
    sx={{
      '& .MuiInputBase-root .MuiInputBase-input': { marginRight: '-0.5rem' },
      '& .MuiInputAdornment-root': { width: '1.5rem' },
      '& .MuiInputAdornment-root .MuiButtonBase-root': { padding: '0.5rem', right: '0.4rem' },
    }}
    slotProps={{
      field: { clearable: true },
      textField: {
        size: 'small',
        autoComplete: 'off',
        onBlur: onBlur,
        variant: 'standard',
        style: { flexGrow: 1, ...style },
        placeholder: placeholder,
      },
    }}
  />
);
