import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import T from 'i18n';
import debounce from 'utils/debounce';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import Badge from '@mui/material/Badge';
import SearchMenuWrapper from './SearchMenuWrapper';
import { Tooltip } from '@mui/material';
import Filters from '../Filters';

export default class extends Component {
  static propTypes = {
    search: PropTypes.any, //.isRequired,
    searchFunctions: PropTypes.object,
  };

  constructor() {
    super();
    this.state = {
      goToGlobalPageFilterFocused: false,
      focused: false,
      value: '',
      filters: {
        goToGlobalPageIdx: '',
        authors: [],
        recipients: [],
        createdBy: [],
        tags: [],
        dateFrom: null,
        dateTo: null,
        dateFilter: false,
        withAnnotations: false,
        withoutAnnotations: false,
        public: false,
        private: false,
        withPublicHyperlinks: false,
        withoutPublicHyperlinks: false,
        trialBundles: [],
        shared: false,
        notShared: false,
      },
      filtersCount: 0,
      freshSearch: true,
      showFilters: false,
      filterPosition: null,
      showSearchMenu: false,
      searchMenuPosition: null,
    };
  }

  clearDocumentSelection = () => {
    this.props.search &&
      this.props.search.documents &&
      this.props.search &&
      this.props.search.documents.forEach(doc => {
        if (doc.selected) {
          doc.selected = false;
        }
      });
  };

  debounceDeepSearchFn = debounce(val => {
    if (!val) this.props.searchFunctions.deepSearchChanged('');
    if (
      (val.length > 1 && this.props.search.isPresentModeWithBackEnd) ||
      (val.length > 0 &&
        !this.props.search.isPresentModeWithBackEnd &&
        !this.props.search.isAllDocsWithLongList) ||
      (val.length > 2 && this.props.search.isAllDocsWithLongList)
    )
      this.props.searchFunctions.deepSearchChanged(val);
  }, 500);

  searchHandler = () => {
    if (this.state.freshSearch) {
      const doSearch = debounce(
        () => {
          this.props.searchFunctions.search && this.props.searchFunctions.search(this.state.value);
          this.setState({ freshSearch: false });
        },
        this.props.searchFunctions && this.props.searchFunctions.search ? 250 : 0,
      );
      doSearch();
    } else {
      this.props.searchFunctions.searchNext && this.props.searchFunctions.searchNext();
    }
  };

  onChangeHandler = value => {
    this.setState({ value, freshSearch: true });
    if (!this.debounceFn)
      this.debounceFn = debounce(val => {
        this.clearDocumentSelection();
        this.props.searchFunctions && this.props.searchFunctions.searchChanged(val);
      }, 350);
    this.debounceFn(value);
  };

  onChangeHandlerForLongList = value => {
    this.setState(prevState => {
      if (value.length < 3 && prevState.value.length > value.length) {
        if (!this.debounceFn)
          this.debounceFn = debounce(() => {
            this.clearDocumentSelection();
            this.props.searchFunctions && this.props.searchFunctions.searchChanged('');
          }, 500);
        this.debounceFn();
      }
      return { value, freshSearch: true };
    });
    if (
      (value.length === 2 && /[a-zA-Z]/.test(value[0]) && /[0-9]/.test(value[1])) ||
      (value.length === 3 &&
        /[a-zA-Z]/.test(value[0]) &&
        /[0-9]/.test(value[1]) &&
        /[0-9]/.test(value[2])) ||
      (value.length > 3 && this.props.search && this.props.search.isPresentModePage) ||
      (value.length > 2 && this.props.search && this.props.search.isAllDocsWithLongList)
    ) {
      if (!this.debounceFn)
        this.debounceFn = debounce(val => {
          this.clearDocumentSelection();
          this.props.searchFunctions && this.props.searchFunctions.searchChanged(val);
        }, 500);
      this.debounceFn(value);
    }
  };

  clear = (removeGoToPageGlobalIdx, updatedFilter) => {
    if (removeGoToPageGlobalIdx || (updatedFilter && Object.keys(updatedFilter).length > 0)) {
      this.setState({
        ...(removeGoToPageGlobalIdx && { goToGlobalPageFilterFocused: false }),
      });
      this.filterHandler({
        ...this.state.filters,
        ...(removeGoToPageGlobalIdx && { goToGlobalPageIdx: '' }),
        ...(updatedFilter && Object.keys(updatedFilter).length > 0 && updatedFilter),
      });
      updatedFilter && Object.keys(updatedFilter).length > 0 && this.handleClickAway();
    } else {
      const cleanFilter = {
        goToGlobalPageIdx: '',
        authors: [],
        recipients: [],
        createdBy: [],
        tags: [],
        dateFrom: null,
        dateTo: null,
        dateFilter: false,
        withAnnotations: false,
        withoutAnnotations: false,
        public: false,
        private: false,
        withPublicHyperlinks: false,
        withoutPublicHyperlinks: false,
        trialBundles: [],
        shared: false,
        notShared: false,
      };
      this.setState(
        {
          goToGlobalPageFilterFocused: false,
          focused: false,
          value: '',
          filters: cleanFilter,
          filtersCount: 0,
          freshSearch: true,
        },
        () => this.props.searchFunctions && this.props.searchFunctions.searchChanged(),
      );
      this.props.searchFunctions &&
        this.props.searchFunctions.filterChanged &&
        this.props.searchFunctions.filterChanged(cleanFilter);
    }
    this.clearDocumentSelection();
  };

  filterHandler = val => {
    for (const key in val) {
      this.setState(prevState => {
        if (Array.isArray(prevState.filters[key]) || Array.isArray(val[key])) {
          return {
            filtersCount:
              prevState.filtersCount + (val[key].length - (prevState.filters[key]?.length || 0)),
          };
        } else if (prevState.filters[key] !== val[key] && val[key]) {
          if (key === 'goToGlobalPageIdx' || key === 'dateFrom' || key === 'dateTo') return;
          else return { filtersCount: prevState.filtersCount + 1 };
        } else if (prevState.filters[key] !== val[key] && !val[key] && prevState.filtersCount > 0)
          if (key === 'goToGlobalPageIdx' || key === 'dateFrom' || key === 'dateTo') return;
          else return { filtersCount: prevState.filtersCount - 1 };
      });
    }
    this.setState({ filters: val });
    this.clearDocumentSelection();
    this.props.searchFunctions.filterChanged(val);
  };

  filterClicked = event => {
    if (this.props.hasDocFinderFilter) {
      this.props.setShowDocFinderFilter(!this.props.showDocFinderFilter);
    } else {
      this.setState({
        showFilters: true,
        filterPosition: event.currentTarget,
      });
    }
  };
  handleClickAway = () => {
    this.setState({ showFilters: false });
  };

  handleSearch = val => {
    this.onChangeHandler(val);
    this.props.searchFunctions.deepSearchChanged(val);
  };

  handleCloseSearchMenu = () => {
    this.setState({ showSearchMenu: false, searchMenuPosition: null });
  };

  handleUserKeyPressed = e => {
    if (this.props.clickToSearch && (e.ctrlKey || e.metaKey) && e.code === 'KeyK') {
      e.preventDefault();
      const searchElem = document.getElementsByClassName('clickToSearch');
      this.setState({ showSearchMenu: true, searchMenuPosition: searchElem[0] });
    }
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleUserKeyPressed);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleUserKeyPressed);
  }

  render() {
    return (
      <>
        <div>
          <Form
            onSubmit={e => {
              e.preventDefault();
            }}
            className="search"
            inline
            style={{ ...(!this.props.hasSearch && { display: 'none' }) }}
          >
            {this.props.search &&
              this.props.search.showGoToPageFilter &&
              !(this.props.searchFunctions && this.props.searchFunctions.search) && (
                <div
                  className={classnames('search-container', {
                    focused: this.state.goToGlobalPageFilterFocused,
                    disableFilter: !(this.props.search && this.props.search.isReady),
                  })}
                  style={{ marginRight: '1rem' }}
                >
                  <FormControl
                    type="search"
                    placeholder={T.translate('case.goToGlobalPage')}
                    className="mr-sm-2"
                    value={this.state.filters.goToGlobalPageIdx}
                    onChange={({ target: { value } }) => {
                      const numericValue = value.replace(/[^\d\.]+/g, '');
                      this.filterHandler({
                        ...this.state.filters,
                        goToGlobalPageIdx: numericValue,
                      });
                      this.setState(prevState => {
                        return {
                          ...prevState.filters,
                          goToGlobalPageIdx: numericValue,
                        };
                      });
                    }}
                    onFocus={() => this.setState({ goToGlobalPageFilterFocused: true })}
                    onBlur={() => this.setState({ goToGlobalPageFilterFocused: false })}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        const numericValue = e.target.value.replace(/[^\d\.]+/g, '');
                        this.filterHandler({
                          ...this.state.filters,
                          goToGlobalPageIdx: numericValue,
                        });
                        this.setState(prevState => {
                          return {
                            ...prevState.filters,
                            goToGlobalPageIdx: numericValue,
                          };
                        });
                        e.stopPropagation();
                        e.preventDefault();
                      }
                    }}
                    disabled={!(this.props.search && this.props.search.isReady)}
                  />
                </div>
              )}
            {this.props.search && Object.keys(this.props.search).length > 0 && (
              <div
                className={classnames('search-container', {
                  focused: this.state.focused && !this.props.clickToSearch,
                  clickToSearch: this.props.clickToSearch,
                })}
                onClick={e =>
                  this.props.clickToSearch &&
                  this.setState({ showSearchMenu: true, searchMenuPosition: e.currentTarget })
                }
              >
                <FormControl
                  type="search"
                  placeholder={
                    (this.props.search && this.props.search.placeholder) ||
                    T.translate('case.search')
                  }
                  className="mr-sm-2"
                  value={this.state.value}
                  onChange={({ target }) => {
                    if (this.props.search && this.props.search.isPresentModeWithBackEnd) {
                      this.props.searchFunctions &&
                        this.props.searchFunctions.clearSearchedFiles(
                          (this.props.search && this.props.search.documents) || [],
                        );
                    }
                    if (
                      this.props.search &&
                      ((this.props.search.isPresentModePage &&
                        this.props.search.useOnlyIdColForPresentMode) ||
                        this.props.search.isAllDocsWithLongList)
                    )
                      this.onChangeHandlerForLongList(target.value.trimStart());
                    else this.onChangeHandler(target.value.trimStart());
                    this.props.search &&
                      (this.props.search.showDeepSearch ||
                        this.props.search.isSearchModePage ||
                        this.props.search.isDocFinderPage ||
                        this.props.search.isPresentModeWithBackEnd) &&
                      this.props.searchFunctions &&
                      this.props.searchFunctions.deepSearchChanged &&
                      this.debounceDeepSearchFn(target.value.trimStart());
                  }}
                  onFocus={() => this.setState({ focused: true })}
                  onBlur={() => this.setState({ focused: false })}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      if (
                        this.props.search &&
                        this.props.search.isPresentModePage &&
                        this.props.search.isClickEnterToPresent
                      ) {
                        this.props.searchFunctions &&
                          this.props.searchFunctions.presentToHearingRoom(
                            (this.props.search && this.props.search.documents) || [],
                            e.target.value,
                          );
                      } else if (
                        this.props.search &&
                        this.props.search.isPresentModeWithBackEnd &&
                        this.props.search.isClickEnterToPresent
                      ) {
                        if (
                          this.props.searchFunctions &&
                          this.props.searchFunctions.presentFirstDoc
                        ) {
                          this.props.searchFunctions.presentFirstDoc(
                            (this.props.search && this.props.search.documents) || [],
                            e.target.value,
                          );
                        }
                      }
                      this.searchHandler();
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }}
                  disabled={!(this.props.search && this.props.search.isReady)}
                  readOnly={this.props.clickToSearch}
                />
              </div>
            )}
            {!(this.props.searchFunctions && this.props.searchFunctions.search) &&
              !(this.props.search && this.props.search.hideFilterOptions) && (
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={this.handleClickAway}
                >
                  <div
                    className={classnames({
                      disableFilter:
                        !(this.props.search && this.props.search.isReady) ||
                        (this.props.search &&
                          this.props.search.isPresentModePage &&
                          this.props.search.useOnlyIdColForPresentMode),
                    })}
                  >
                    <Tooltip title={T.translate('case.filter')}>
                      <div className="filterButton" onClick={this.filterClicked}>
                        <Badge badgeContent={this.state.filtersCount} color="primary">
                          <FilterListRoundedIcon />
                        </Badge>
                      </div>
                    </Tooltip>
                    <Popper
                      id={'simple-popover'}
                      open={Boolean(this.state.showFilters)}
                      anchorEl={this.state.filterPosition}
                      disablePortal={true}
                      placement="bottom-end"
                    >
                      <Filters
                        defaultFilters={this.state.filters}
                        filterHandler={this.filterHandler}
                        documents={this.props.search && this.props.search.documents}
                        columns={this.props.search && this.props.search.selectedColumns}
                        isAnnotation={this.props.search && this.props.search.isAnnotation}
                      />
                    </Popper>
                  </div>
                </ClickAwayListener>
              )}
            {this.props.searchFunctions && this.props.searchFunctions.search && (
              <Button
                variant="light"
                onClick={this.searchHandler}
                className={classnames({
                  loading: this.props.search && this.props.search.isLoading,
                })}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            )}
          </Form>
        </div>
        {this.props.clickToSearch && this.state.showSearchMenu && (
          <SearchMenuWrapper
            handleCloseSearchMenu={this.handleCloseSearchMenu}
            searchMenuPosition={this.state.searchMenuPosition}
            handleSearch={this.handleSearch}
            searchVal={this.state.value}
          />
        )}
      </>
    );
  }
}
