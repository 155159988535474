/* eslint-disable no-restricted-globals */
export default () => {
  self.addEventListener('message', event => {
    const data = event.data;

    const performIntensiveTask = () => {
      const {
        documents,
        filterOptions,
        trialBundlesMemo,
        tagsFetched,
        defaultFilterOptions,
      } = data;

      const toString = (obj: any) => {
        if (obj === null) return 'null';
        if (typeof obj === 'boolean' || typeof obj === 'number') return obj.toString();
        if (typeof obj === 'string') return obj;
        if (typeof obj === 'symbol') throw new TypeError();
        return obj.toString();
      };

      const defaultCompare = (x: any, y: any) => {
        if (x === undefined && y === undefined) return 0;
        if (x === undefined) return 1;
        if (y === undefined) return -1;
        const xString = toString(x);
        const yString = toString(y);
        if (xString < yString) return -1;
        if (xString > yString) return 1;
        return 0;
      };

      const reduceTrialBundleFolders = (folders: any) =>
        folders
          .reduce(
            (acc: any, item: any) =>
              ([] as any).concat(
                acc,
                !(item.name === 'index' && item.readOnly)
                  ? {
                      name: item.name,
                      id: item.id,
                      level: item.level > -1 ? item.level + 1 : 0,
                      group: (Array.isArray(item.folders) && item.folders.length > 0) || !item.code,
                    }
                  : [],
                Array.isArray(item.folders) && item.folders.length > 0
                  ? reduceTrialBundleFolders(item.folders)
                  : [],
              ),
            [],
          )
          .filter(Boolean);

      try {
        if (!(documents && documents.length > 0)) {
        } else {
          if (filterOptions?.length > 0) {
            return filterOptions;
          } else {
            const allValues = documents.reduce((acc: any, doc: any) => {
              return {
                authors: doc.author ? (acc.authors || []).concat(doc.author) : acc.authors || [],
                recipients: doc.recipient
                  ? (acc.recipients || []).concat(doc.recipient)
                  : acc.recipients || [],
                createdBy: doc.createdBy
                  ? (acc.createdBy || []).concat(doc.createdBy)
                  : acc.createdBy || [],
                entities: Object.assign(
                  {},
                  acc.entities || {},
                  Object.keys(doc.entities || {})
                    .filter(key => key !== 'cost')
                    .reduce((ncc: any, key) => {
                      const obj = {} as any;
                      obj[key] = [].concat(
                        ncc[key] || [],
                        doc.entities[key],
                        (acc.entities || {})[key] || [],
                      );

                      return Object.assign({}, ncc, obj);
                    }, {}),
                ),
              };
            }, {});
            const entities = Object.keys(allValues.entities).reduce((acc: any, key: any) => {
              const obj = {} as any;
              obj[key] = Array.from(
                new Map(
                  allValues.entities[key].map((e: any) => [e && e.toLowerCase(), e]),
                ).values(),
              )
                .sort()
                .filter(Boolean);

              return Object.assign({}, acc, obj);
            }, {});
            const authors = Array.from(new Set(allValues.authors)).sort();
            const recipients = Array.from(new Set(allValues.recipients)).sort();
            const createdBySet = Array.from(
              new Set(allValues.createdBy.map((item: any) => item.id)),
            )
              .map((id: any) => allValues.createdBy.find((item: any) => item.id === id))
              .sort((a, b) => defaultCompare(a.name, b.name));
            const trialBundles = reduceTrialBundleFolders(trialBundlesMemo);
            return ([] as any).concat(
              defaultFilterOptions.map((option: any) => {
                const originalArray =
                  option.filterKey === 'authors'
                    ? authors
                    : option.filterKey === 'recipients'
                    ? recipients
                    : option.filterKey === 'createdBy'
                    ? createdBySet
                    : option.filterKey === 'tags'
                    ? tagsFetched
                    : option.filterKey === 'trialBundles'
                    ? trialBundles
                    : [];
                return Object.assign(option, { originalArray: originalArray });
              }),
              Object.keys(entities).map((entityKey: any) => ({
                filterName: `filters.${entityKey}`,
                filterKey: entityKey,
                originalArray: entities[entityKey],
              })),
            );
          }
        }
      } catch (e) {
        console.log(e);
        return [];
      }
    };

    const result = performIntensiveTask();
    self.postMessage(result);
  });
};
