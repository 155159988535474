import React, { useEffect, useImperativeHandle } from 'react';
import { useDrag } from 'react-dnd';
import classnames from 'classnames';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Draggable } from 'react-beautiful-dnd';
import { findLastIndex } from 'utils/arrays';
import Row from './Row';
import { clearSelection } from 'utils/browser';

type DraggableRowProps = any;

export default React.memo(
  React.forwardRef((props: DraggableRowProps, ref) => {
    const {
      style,
      index,
      row,
      sortMode,
      editMode,
      additionalDragInfo,
      onDoubleClick,
      onRowSelected,
      setSelectedRowsIds,
      selectedRowIds = [],
      allRows,
      selected,
      shiftPressedIndex,
      setShiftPressedIndex,
      hoverable,
      readOnly,
      selectedRowIdsLength,
      focusNextRow,
      handleSaveChanges,
      handleCancelChanges,
      onRowClick,
      currentlyEditingRowIndex,
      firstEditableCellRef,
      mappedRowsWithErrors,
      ...rest
    } = props;

    const unselectAllRows = () => {
      allRows.forEach((row: any) => {
        row.selected = false;
      });
    };

    useImperativeHandle(ref, () => ({
      unselectAllRows: () => {
        const rowsSelected: any[] = [];

        unselectAllRows();

        setSelectedRowsIds(rowsSelected.map(({ compositeKey, id }) => compositeKey || id));

        onRowSelected && onRowSelected(rowsSelected);
      },
    }));

    const onClickHandler = (e: React.MouseEvent<HTMLTableRowElement>) => {
      const rowsSelected = [];

      //if shifts pressed
      if (e.shiftKey) {
        clearSelection();

        //if no rows selected select from first to current
        let indexLast = 0;

        //if shift was pressed last selection select from first in selected array till current
        if (shiftPressedIndex !== null) {
          indexLast = shiftPressedIndex;
        } else {
          //if shift was not pressed last selection select from last in selected array till current
          const lastSelectedIndex = findLastIndex(
            allRows,
            ({ selected }: { selected: any }) => selected,
          );
          if (lastSelectedIndex) {
            indexLast = lastSelectedIndex;
          }
        }

        const start = indexLast < index ? indexLast : index;
        const end = indexLast < index ? index + 1 : indexLast + 1;

        unselectAllRows();
        for (let i = start; i < end; i++) {
          allRows[i].selected = true;
          rowsSelected.push(allRows[i]);
        }

        setShiftPressedIndex(indexLast);
      }
      //if crtl pressed
      else if (e.ctrlKey || e.metaKey) {
        row.selected = !row.selected;
        allRows.forEach((r: any) => r.selected && rowsSelected.push(r));
        setShiftPressedIndex(null);
      }
      // nothing pressed
      else {
        allRows.forEach((r: any) => (r.selected = false));
        row.selected = !row.selected;
        rowsSelected.push(row);
        setShiftPressedIndex(null);
      }

      setSelectedRowsIds(rowsSelected.map(({ compositeKey, id }) => compositeKey || id));
      onRowSelected && onRowSelected(rowsSelected);
      onRowClick(index);
    };

    // TO-DO remove business logic - no annotation shite
    const rowProps = {
      className: classnames({
        selected: selected && row.type !== 'annotation',
        selectedAnnotation: selected && row.type === 'annotation',
      }),
      row,
      sortMode,
      editMode,
      onDoubleClick: (e: React.MouseEvent<HTMLTableRowElement>) =>
        !editMode && onDoubleClick && onDoubleClick(row, e),
      onClick: readOnly ? () => null : onClickHandler,
      index,
      selected,
      hoverable,
      focusNextRow,
      handleSaveChanges,
      handleCancelChanges,
      onRowClick,
      currentlyEditingRowIndex,
      firstEditableCellRef,
      selectedRowIdsLength,
      onRowSelected,
      mappedRowsWithErrors,
      ...rest,
    };

    // TO-DO remove business logic - no annotation document shite
    const [, drag, preview] = useDrag({
      type: row.type === 'annotation' ? 'ANNOTATION' : row.type === 'event' ? 'EVENT' : 'DOCUMENT',
      item: {
        documentIds: selected ? selectedRowIds : [row.compositeKey || row.id],
        dragRow: row,
        type:
          row.type === 'annotation' ? 'ANNOTATION' : row.type === 'event' ? 'EVENT' : 'DOCUMENT',
        ...additionalDragInfo,
      },
      options: {
        dropEffect: 'copy',
      },
    });

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    return sortMode ? (
      <Draggable
        key={row.compositeKey || row.id}
        index={index}
        draggableId={row.id}
        isDragDisabled={row.agreed || props.isLocked}
      >
        {draggableProvided => {
          return (
            <Row
              {...rowProps}
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              style={{
                ...style,
                ...draggableProvided.draggableProps.style,
              }}
              dragHandleProps={draggableProvided.dragHandleProps}
            />
          );
        }}
      </Draggable>
    ) : (
      <Row dragHandleProps={{ ref: drag }} style={style} {...rowProps} />
    );
  }),
);
