/* eslint-disable react-hooks/exhaustive-deps */
import './UploadFileContent.scss';

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Button,
  SelectChangeEvent,
  IconButton,
  Tooltip,
  Menu,
  Divider,
  ListItemText,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { createBatch, fetchBatchReport, parseTemplates, exportConfig } from './utils';
import { ParsedTemplate } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import api from 'common/api';
import T from 'i18n';
import {
  selectCurrentCaseId,
  selectCurrentFolderId,
  selectDataMapFailedRows,
  selectDocuments,
  selectUserDataRoomSettings,
} from 'common/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'features/common';
import { fileIdentifiers, fileIdsArray } from './constants';
import MappingErrors from './MappingErrors';
import MappingPreview from './MappingPreview';
import MappingSummary from './MappingSummary';
import { FilterList, FilterListOff, KeyboardArrowDown } from '@mui/icons-material';
import { setShowFilteredRows } from '../redux/dataMap';
import { useCSVMapping } from './hooks/useCSVMapping';

type DataMapperprops = {
  setBatchId: any;
  setUploading: any;
  setUploadComplete: any;
  setBatchUploadReport: any;
  setBatchSize: any;
  setMappedRowsWithErrors: any;
  mappedRowsWithErrors: any;
};

const DataMapper = ({
  setBatchId,
  setUploading,
  setUploadComplete,
  setBatchUploadReport,
  setBatchSize,
  setMappedRowsWithErrors,
  mappedRowsWithErrors,
}: DataMapperprops) => {
  const dispatch = useDispatch();

  const documents = useSelector(selectDocuments) as Array<any>;
  const currentFolderId = useSelector(selectCurrentFolderId) as string;
  const caseId = useSelector(selectCurrentCaseId) as string;

  const {
    mappingFile,
    setMappingFile,

    mappingData,
    setMappingData,

    parsedData,
    setParsedData,

    columns,
    setColumns,

    parseFile,

    failedRows,
    setFailedRows,

    runValidationInHook,
  } = useCSVMapping();

  const [primaryKey, setPrimaryKey] = useState<string>('');
  const [columnMappings, setColumnMappings] = useState<{ [key: string]: string }>({});
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const [templates, setTemplates] = useState<ParsedTemplate[]>([]);
  const [templateError, setTemplateError] = useState<string>('');
  const [selectedTemplate, setSelectedTemplate] = useState<ParsedTemplate | null>(null);
  const [mappingInProgress, setMappingInProgress] = useState<boolean>(false);
  const [templatesFetched, setTemplatesFetched] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [exportMenuAnchor, setExportMenuAnchor] = useState<null | HTMLElement>(null);

  const filterRows = useSelector(selectDataMapFailedRows);
  const userDataRoomSettings = useSelector(selectUserDataRoomSettings);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = () => {
    setExportMenuAnchor(null);
  };

  const errorRows = failedRows.filter(item => item.type === 'error');
  const warnings = failedRows.filter(item => item.type === 'warning');

  const handleTemplateChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value as string;
    const template = templates.find(t => t?.id === selectedId) || null;
    setSelectedTemplate(template);
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];
    setMappingFile(file);
    parseFile(file);
    // Fetch templates only once after primary key is selected
    if (!templatesFetched) {
      try {
        const response: any = await api.get(`/cases/${caseId}/batch-templates?type=map`);
        parseTemplates(response, setTemplates);
        setTemplatesFetched(true);
      } catch (error) {
        setTemplateError(T.translate('case.batchUpload.dataMapper.templateError'));
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['.csv'],
    onDrop,
    multiple: false,
    disabled: mappingInProgress,
  });

  const handlePrimaryKeyChange = async (event: SelectChangeEvent<string>) => {
    const newPrimaryKey = event.target.value as string;
    setPrimaryKey(newPrimaryKey);
    if (errors.primaryKey) {
      setErrors(prev => ({ ...prev, primaryKey: false }));
    }
  };

  const handleMappingChange = (requiredField: string) => (event: SelectChangeEvent<string>) => {
    const selectedColumn = event.target.value as string;
    setMappedRowsWithErrors([]);
    setColumnMappings(prev => ({
      ...prev,
      [requiredField]: selectedColumn,
    }));
    if (errors[requiredField]) {
      setErrors(prev => ({
        ...prev,
        [requiredField]: false,
      }));
    }
  };

  const triggerBatchProcessing = async (batchId: any) => {
    try {
      const response: any = await api.put(`/cases/${caseId}/batch/${batchId}`);
      if (!response || response.error) {
        throw new Error(T.translate('case.batchUpload.triggerBatchError'));
      }
    } catch (error) {
      throw new Error(T.translate('case.batchUpload.triggerBatchError'));
    }
  };

  const displayFileIdentifierHandler = (id: string) => {
    const translation = T.translate(fileIdentifiers?.get(id) as string);

    return T.translate('case.batchUpload.dataMapper.fileIdentifier', {
      fileIdentifier: translation,
    });
  };

  const handleMappingSubmit = async () => {
    setUploading(true);
    setMappingInProgress(true);
    setApiError(null);
    if (mappingData.length === 0) {
      console.warn('No valid data to submit.');
      return;
    }

    try {
      const jsonDataPayload = mappingData;

      const batchResponse: any = await createBatch(
        caseId,
        currentFolderId,
        jsonDataPayload,
        selectedTemplate?.id || '',
      );
      const batchId = batchResponse.id;

      await triggerBatchProcessing(batchId);
      await fetchBatchReport(caseId, batchId, setBatchUploadReport);

      clearMappingData();
      setBatchId(batchId);
      setUploadComplete(true);
      setUploading(false);
    } catch (error) {
      const detectedError: any = error;
      setMappingInProgress(false);
      setApiError(
        detectedError
          ? detectedError?.message
          : T.translate('case.batchUpload.dataMapper.apiErrorGeneral'),
      );
      setUploadComplete(true);
      setUploading(false);
    }
  };

  const attemptAutoMapping = () => {
    if (!selectedTemplate || !columns.length) return;

    setColumnMappings(prev => {
      const newMappings = { ...prev };

      selectedTemplate?.requiredFields?.forEach(field => {
        if (newMappings[field]) {
          return;
        }

        let autoMappedColumn = columns?.find(c => c.toLowerCase() === field.toLowerCase());
        if (field.toLowerCase() === 'docdate' && !autoMappedColumn) {
          autoMappedColumn = columns?.find(col => col.toLowerCase().includes('date'));
        }
        if (!autoMappedColumn) {
          if (fileIdsArray?.includes(field.toLowerCase() || field)) {
            autoMappedColumn = columns?.find(c => c.toLowerCase() === field.toLowerCase());
          }
        }
        if (autoMappedColumn) {
          newMappings[field] = autoMappedColumn;
        }
      });
      return newMappings;
    });
  };

  const exportMenuConfig = useMemo(
    () =>
      exportConfig({
        failedRows,
        documents,
        rawCSVRows: parsedData,
        columns: userDataRoomSettings?.columns,
      }),
    [failedRows, documents, parsedData],
  );

  const exportCounts = useMemo(() => {
    const counts: Record<string, number> = {};
    exportMenuConfig.forEach(item => {
      counts[item.key] = failedRows.filter(item.filterFn).length;
    });
    return counts;
  }, [failedRows, exportMenuConfig]);

  const handleFilterRows = () => {
    dispatch(setShowFilteredRows(!filterRows));
  };

  const clearMappingData = () => {
    setMappingFile(null);
    setColumnMappings({});
    setPrimaryKey('');
    setErrors({});
    setParsedData([]);
    setColumns([]);
    setFailedRows([]);
    setSelectedTemplate(null);
    setMappingData([]);
    setMappingInProgress(false);
    setApiError(null);
    setMappingInProgress(false);
    setMappedRowsWithErrors([]);
    setTemplateError('');
    setTemplatesFetched(false);
    dispatch(setShowFilteredRows(false));
  };

  useEffect(() => {
    if (selectedTemplate?.id) {
      const processedData = runValidationInHook({
        documents,
        selectedTemplate,
        primaryKey,
        columnMappings,
        setMappedRowsWithErrors,
        errors,
      });
      setBatchSize(processedData.length);
    }
  }, [selectedTemplate, primaryKey, columnMappings, documents]);

  useEffect(() => {
    if (selectedTemplate && columns.length > 0) {
      attemptAutoMapping();
    }
  }, [selectedTemplate, columns]);

  useEffect(() => {
    return () => {
      clearMappingData();
    };
  }, []);

  return (
    <div
      style={{
        padding: '20px',
      }}
    >
      <div
        {...getRootProps()}
        style={{
          border: `2px dashed ${isDragActive ? '#0078d4' : '#cccccc'}`,
          padding: '20px',
          textAlign: 'center',
          borderRadius: 6,
          cursor: 'pointer',
          marginBottom: '20px',
          backgroundColor: isDragActive ? '#f0f8ff' : 'transparent',
        }}
      >
        <input {...getInputProps()} />
        <p>{T.translate('case.batchUpload.dataMapper.uploadCSV')}</p>
      </div>
      {mappingFile ? (
        <span style={{ display: 'flex', flexDirection: 'row' }}>
          <p>
            {T.translate('case.batchUpload.dataMapper.mapHeadings.postMapFileAttached', {
              fileCount: documents?.length,
            })}
          </p>
          <Tooltip
            title={
              filterRows
                ? T.translate('case.batchUpload.dataMapper.stopFiltering', {
                    defaultValue: 'Stop filtering by failed rows',
                  })
                : T.translate('case.batchUpload.dataMapper.startFiltering', {
                    defaultValue: 'Filter by failed rows',
                  })
            }
          >
            <IconButton style={{ marginLeft: 'auto' }} onClick={handleFilterRows}>
              {filterRows ? <FilterListOff /> : <FilterList />}
            </IconButton>
          </Tooltip>
        </span>
      ) : (
        <p>
          {T.translate('case.batchUpload.dataMapper.mapHeadings.preMapFileAttached', {
            fileCount: documents?.length,
          })}
        </p>
      )}
      {mappingFile && (
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="body1">{mappingFile.name}</Typography>
          <Button variant="text" color="secondary" onClick={clearMappingData}>
            {T.translate('case.batchUpload.dataMapper.reset')}
          </Button>
        </Box>
      )}

      {templates.length > 0 &&
        mappingFile &&
        (templatesFetched ? (
          <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            error={Boolean(templateError)}
            required
            disabled={mappingInProgress}
          >
            <InputLabel id="template-select-label">
              {T.translate('case.batchUpload.dataMapper.selectTemplate')}
            </InputLabel>
            <Select
              labelId="template-select-label"
              value={selectedTemplate?.id || ''}
              onChange={handleTemplateChange}
              label={T.translate('case.batchUpload.dataMapper.selectTemplate')}
            >
              {templates.map((template: any) => (
                <MenuItem key={template?.id} value={template?.id}>
                  {template?.name}
                </MenuItem>
              ))}
            </Select>
            {templateError && (
              <Box display="flex" alignItems="center" mt={1}>
                <Typography variant="caption" color="error">
                  {templateError}
                </Typography>
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  style={{
                    color: 'red',
                    marginLeft: '5px',
                  }}
                />
              </Box>
            )}
          </FormControl>
        ) : (
          <Spinner style={{ height: '1.5rem' }} />
        ))}

      {columns.length > 0 && selectedTemplate && (
        <FormControl
          fullWidth
          variant="outlined"
          margin="normal"
          error={Boolean(errors.primaryKey)}
          required
          disabled={mappingInProgress}
        >
          <Box display="flex" alignItems="center">
            <InputLabel id="primary-key-label">
              {T.translate('case.batchUpload.dataMapper.primaryKey')}
            </InputLabel>
          </Box>
          <Select
            labelId="primary-key-label"
            value={primaryKey}
            onChange={handlePrimaryKeyChange}
            error={Boolean(errors.primaryKey)}
            required
            label={T.translate('case.batchUpload.dataMapper.primaryKey')}
          >
            {columns.map(col => (
              <MenuItem key={col} value={col}>
                {col}
              </MenuItem>
            ))}
          </Select>
          <p className="under-label">
            {displayFileIdentifierHandler(selectedTemplate?.fileIdentifier || '')}
          </p>
          {errors.primaryKey && (
            <Box display="flex" alignItems="center" mt={1}>
              <Typography variant="caption" color="error">
                {T.translate('case.batchUpload.dataMapper.fileIdentifierRequired')}
              </Typography>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{
                  color: 'red',
                  marginLeft: '5px',
                }}
              />
            </Box>
          )}
        </FormControl>
      )}
      {columns.length > 0 && (selectedTemplate?.requiredFields?.length as any) > 0 && (
        <Box display="flex" flexDirection="column" gap={3} mt={2}>
          {selectedTemplate?.requiredFields.map((field: any) => (
            <FormControl
              key={field}
              fullWidth
              variant="outlined"
              margin="normal"
              disabled={mappingInProgress}
            >
              <InputLabel id={`${field}-mapping-label`}>
                {T.translate(fileIdentifiers?.get(field) as string)}
              </InputLabel>
              <Select
                labelId={`${field}-mapping-label`}
                value={columnMappings[field] || ''}
                onChange={handleMappingChange(field)}
                label={field}
              >
                {columns
                  .filter(
                    col =>
                      !Object.values(columnMappings).includes(col) || columnMappings[field] === col,
                  )
                  .map(col => (
                    <MenuItem key={col} value={col}>
                      {col}
                    </MenuItem>
                  ))}
              </Select>

              {field.toLowerCase().includes('date') &&
                selectedTemplate?.formatsForDates?.length > 0 && (
                  <Typography variant="caption" color="textSecondary" mt={1}>
                    {`Format: ${selectedTemplate?.formatsForDates?.join(', ')}`}
                  </Typography>
                )}

              {columnMappings[field] && (
                <Typography variant="body2" key={field}>
                  {`${columnMappings[field]} from CSV → ${T.translate(
                    fileIdentifiers?.get(field) as string,
                  )} in TrialView`}
                </Typography>
              )}
            </FormControl>
          ))}
        </Box>
      )}
      <MappingErrors
        failedRows={failedRows}
        exclusionMessage={
          errorRows?.length ? T.translate('case.batchUpload.advancedSettings.rowsExcluded') : ''
        }
        normalMessage={
          warnings?.length ? T.translate('case.batchUpload.advancedSettings.fieldsNotMapped') : ''
        }
        rawCSVRows={parsedData}
        documents={documents}
        mappedRowsWithErrors={mappedRowsWithErrors}
      />
      {apiError && (
        <Box mt={2}>
          <Typography variant="body1" color="error">
            {apiError}
          </Typography>
        </Box>
      )}

      {mappingData && mappingData.length > 0 && (
        <MappingSummary failedRows={failedRows} mappingData={mappingData} />
      )}

      {mappingFile && (
        <Box display="flex" justifyContent="space-between" mt={3}>
          {failedRows.length > 0 && (
            <>
              <Button
                id="export-menu-button"
                aria-haspopup="true"
                variant="contained"
                disableElevation
                onClick={handleMenuOpen}
                endIcon={<KeyboardArrowDown />}
              >
                {T.translate('case.batchUpload.dataMapper.exportByError', {
                  defaultValue: 'Export',
                })}
              </Button>

              <Menu
                anchorEl={exportMenuAnchor}
                open={Boolean(exportMenuAnchor)}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                sx={{ mt: 1 }}
              >
                {exportMenuConfig?.map((menuItem: any) => {
                  const count = exportCounts[menuItem.key] || 0;
                  const disabled = count === 0;
                  const labelWithCount = `${menuItem.label} (${count})`;

                  return menuItem.key === 'separator' ? (
                    <Divider />
                  ) : (
                    <MenuItem
                      key={menuItem.key}
                      disabled={disabled}
                      onClick={() => {
                        handleMenuClose();
                        menuItem.handlerFn();
                      }}
                    >
                      <ListItemText>{labelWithCount}</ListItemText>
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setPreviewOpen(true)}
            disabled={!mappingFile || !selectedTemplate || mappingInProgress}
          >
            {mappingInProgress ? (
              <Spinner style={{ height: '1.5rem' }} />
            ) : (
              T.translate('case.batchUpload.dataMapper.submit')
            )}
          </Button>
        </Box>
      )}
      <MappingPreview
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        mappingData={mappingData}
        fileIdentifier={selectedTemplate?.fileIdentifier}
        setMappingData={setMappingData}
        failedRows={failedRows}
        setFailedRows={setFailedRows}
        formatsForDates={selectedTemplate?.formatsForDates as string[]}
        requiredFields={selectedTemplate?.requiredFields as string[]}
        onSubmitMapping={handleMappingSubmit}
        fieldsWithAppend={selectedTemplate?.fieldsWithAppend as string[]}
      />
    </div>
  );
};

export default DataMapper;
