import React, { useState, memo } from 'react';
import { IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { CopyAllRounded } from '@mui/icons-material';

// TODO: Update this to an actual type
type ExistingHyperlink = any;

function shortenString(str: string | undefined): string {
  if (!str) return '';
  if (str.length > 75) {
    return `${str.substring(0, 30)}...${str.substring(str.length - 30)}`;
  }
  return str;
}

type ExistingHyperlinkRowProps = {
  ann: ExistingHyperlink;
  classes?: any;
};

function ExistingHyperlinkRowComponent({ ann, classes }: ExistingHyperlinkRowProps) {
  const [copyFeedback, setCopyFeedback] = useState<string | null>(null);

  const handleCopyText = async () => {
    if (!navigator.clipboard?.writeText) {
      setCopyFeedback('Copy not supported');
      return;
    }
    try {
      await navigator.clipboard.writeText(window.location.origin + (ann.annotation.href || ''));
      setCopyFeedback('Copied!');
      setTimeout(() => setCopyFeedback(null), 2000);
    } catch (err) {
      setCopyFeedback('Failed to copy');
      setTimeout(() => setCopyFeedback(null), 2000);
    }
  };

  return (
    <tr>
      <td className={classes.td}>{ann.annotation.pageNumber}</td>
      <td className={classes.td}>{shortenString(ann.annotation.selectedText)}</td>
      <td className={classes.td}>{ann.annotation.data?.linkId}</td>
      <td className={classes.td}>{ann.createdBy?.name}</td>
      <td className={classes.td} style={{ whiteSpace: 'nowrap' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link to={ann.annotation.href || '#'} style={{ color: '#748CEC' }} target="_blank">
            {shortenString(ann.fileName)}
          </Link>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }}>
            {copyFeedback === 'Copied!' && (
              <Typography variant="body2" style={{ color: '#60D362', marginRight: 4 }}>
                {copyFeedback}
              </Typography>
            )}
            <IconButton size="small" onClick={handleCopyText} title="Copy hyperlink">
              <CopyAllRounded fontSize="small" />
            </IconButton>
          </div>
        </div>
      </td>
    </tr>
  );
}

export const ExistingHyperlinkRow = memo(ExistingHyperlinkRowComponent);
