import React, { useEffect, forwardRef, useState, useRef } from 'react';
import { getHighlightedText } from 'utils/highlightText';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGripVertical,
  faGripHorizontal,
  faLock,
  faLockOpen,
  faShareAlt,
} from '@fortawesome/free-solid-svg-icons';
import T from 'i18n';
import classnames from 'classnames';
import { withResizeDetector } from 'react-resize-detector';
import SortHeader from '../TableCase/SortHeader';
import { Col, Row } from 'react-bootstrap';
import { onCustomSortDate, onCustomSortCreatedByName, onCustomSortText } from '../TableCase/utils';

export default (
  sortMode: any,
  setSortMode: any,
  updateDocumentSort: any,
  clearFilter: any,
  filterVal: any,
  isAllAnnotations: any,
  isTimeline: any,
) =>
  [
    {
      id: 'index',
      Header: !isAllAnnotations
        ? () => {
            const icon = sortMode ? faLockOpen : faLock;
            return (
              <OverlayTrigger
                placement="top"
                delay={{ show: 500, hide: 400 }}
                overlay={
                  <Popover id="index">
                    <Popover.Content>
                      {sortMode
                        ? T.translate('case.exitSortMode')
                        : T.translate('case.enterSortMode')}
                    </Popover.Content>
                  </Popover>
                }
              >
                <FontAwesomeIcon
                  style={{ marginBottom: '4px', width: '100%', minWidth: '10px' }}
                  icon={icon}
                />
              </OverlayTrigger>
            );
          }
        : null,
      sort: false,
      onClick: ({ setSortByColumn, setSortDirection, onSort, event }: any) => {
        setSortMode(!sortMode);
        setSortByColumn(null);
        setSortDirection(null);
        onSort({
          sortByColumn: null,
          sortDirection: null,
          event,
        });
        clearFilter();
        event.stopPropagation();
      },
      Cell: forwardRef(
        (
          { dragHandleProps, sortMode, isScrolling, rowIndex, rowHover, rowSelected, ...row }: any,
          ref,
        ) => {
          const icon = sortMode ? faGripHorizontal : faGripVertical;
          const showHandle = rowHover || rowSelected;
          return (
            <div
              style={{
                color: '#9A9A9A',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <div
                {...dragHandleProps}
                className="draggable hover-change"
                ref={ref}
                onClick={e => e.stopPropagation()}
                style={{
                  marginBottom: '2px',
                  minWidth: '10px',
                  // width: '10px',
                }}
              >
                <span className={classnames({ new: !showHandle, old: showHandle })}>
                  {rowIndex + 1}
                </span>
                <FontAwesomeIcon
                  className={classnames({ new: showHandle, old: !showHandle })}
                  icon={icon}
                />
              </div>
            </div>
          );
        },
      ),
      forwardRef: true,
      minWidth: '60px',
      width: `12rem`,
    },
    !isTimeline && {
      id: 'fileId',
      Header: () => (
        <SortHeader
          text="case.documentId"
          onSortDownClick={() => updateDocumentSort('fileId', 'asc', onCustomSortText)}
          onSortUpClick={() => updateDocumentSort('fileId', 'desc', onCustomSortText)}
          sortMode={sortMode}
        />
      ),
      Cell: (result: any) => (
        <span
          className="multiple-line-overflow"
          dangerouslySetInnerHTML={{
            __html: getHighlightedText(result.fileId || '', filterVal),
          }}
        ></span>
      ),
      minWidth: '110px',
      width: `12rem`,
      onCustomSort: onCustomSortText,
      ...(sortMode && { sort: false }),
    },
    !isTimeline && {
      id: 'text',
      Header: () => (
        <SortHeader
          text="case.selectedText"
          onSortDownClick={() => updateDocumentSort('text', 'asc', onCustomSortText)}
          onSortUpClick={() => updateDocumentSort('text', 'desc', onCustomSortText)}
          sortMode={sortMode}
        />
      ),
      minWidth: '250px',
      width: `calc(100% - ${isAllAnnotations ? '75rem' : '105rem'})`,
      Cell: withResizeDetector(({ width, height, ...result }: any) => {
        const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
        const annotation = isAllAnnotations ? result.annotation : result.annotation.annotation;

        const measuredDescRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
          setShouldShowTooltip(
            measuredDescRef.current !== null &&
              measuredDescRef.current.offsetHeight < measuredDescRef.current.scrollHeight,
          );
        }, [width, height]);

        return (
          <>
            {annotation.type === 'RectangleAnnotation' && annotation.data.image ? (
              <OverlayTrigger
                placement="top"
                delay={{ show: 500, hide: 400 }}
                trigger={['hover', 'focus']}
                overlay={
                  <Popover
                    id="textRect"
                    style={{ pointerEvents: 'none', maxWidth: '30%', maxHeight: '40%' }}
                  >
                    <Popover.Content>
                      <div>
                        <img
                          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                          src={annotation.data.image}
                          alt={'annotation-screenshot-full'}
                        />
                      </div>
                    </Popover.Content>
                  </Popover>
                }
              >
                <div
                  ref={measuredDescRef}
                  style={{
                    overflow: 'hidden',
                    maxHeight: '38px',
                    maxWidth: '500px',
                    border: '1px dashed rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <img
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    src={annotation.data.image}
                    alt={'annotation-screenshot'}
                  />
                </div>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement="top"
                delay={{ show: 500, hide: 400 }}
                trigger={shouldShowTooltip ? ['hover', 'focus'] : []}
                overlay={
                  <Popover id="text" style={{ pointerEvents: 'none' }}>
                    <Popover.Content>{annotation.selectedText || ''}</Popover.Content>
                  </Popover>
                }
              >
                <div
                  ref={measuredDescRef}
                  className="multiple-line-overflow"
                  dangerouslySetInnerHTML={{
                    __html: getHighlightedText(annotation.selectedText || '', filterVal),
                  }}
                ></div>
              </OverlayTrigger>
            )}
          </>
        );
      }),
      onCustomSort: onCustomSortText,
      ...(sortMode && { sort: false }),
    },
    {
      id: 'comment',
      Header: () => (
        <SortHeader
          text={isTimeline ? 'case.date' : 'case.comment'}
          onSortDownClick={() =>
            updateDocumentSort(
              isTimeline ? 'date' : 'comment',
              'asc',
              isTimeline ? onCustomSortDate : onCustomSortText,
            )
          }
          onSortUpClick={() =>
            updateDocumentSort(
              isTimeline ? 'date' : 'comment',
              'desc',
              isTimeline ? onCustomSortDate : onCustomSortText,
            )
          }
          sortMode={sortMode}
        />
      ),
      Cell: (result: any) => {
        const row = isTimeline
          ? result
          : isAllAnnotations
          ? result.annotation
          : result.annotation.annotation;
        return (
          <span
            className="multiple-line-overflow"
            dangerouslySetInnerHTML={{
              __html: getHighlightedText(
                isTimeline ? row.date || '' : row.data.comment || '',
                filterVal,
              ),
            }}
          ></span>
        );
      },
      minWidth: isTimeline ? '100px' : '210px',
      width: isTimeline ? '7rem' : `15rem`,
      onCustomSort: isTimeline ? onCustomSortDate : onCustomSortText,
      ...(sortMode && { sort: false }),
    },
    !isAllAnnotations && {
      id: 'notesCol',
      Header: () => (
        <SortHeader
          text={isTimeline ? 'case.event' : 'case.note'}
          onSortDownClick={() =>
            updateDocumentSort(isTimeline ? 'name' : 'notes', 'asc', onCustomSortText)
          }
          onSortUpClick={() =>
            updateDocumentSort(isTimeline ? 'name' : 'notes', 'desc', onCustomSortText)
          }
          sortMode={sortMode}
        />
      ),
      minWidth: isTimeline ? '300px' : '200px',
      width: isTimeline ? '40rem' : `20rem`,
      Cell: withResizeDetector(({ width, height, ...result }: any) => {
        const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

        const measuredDescRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
          setShouldShowTooltip(
            measuredDescRef.current !== null &&
              measuredDescRef.current.offsetHeight < measuredDescRef.current.scrollHeight,
          );
        }, [width, height]);

        return (
          <OverlayTrigger
            placement="left"
            delay={{ show: 500, hide: 400 }}
            trigger={shouldShowTooltip ? ['hover', 'focus'] : []}
            overlay={
              <Popover id="notesCol" style={{ pointerEvents: 'none' }}>
                <Popover.Content>{(isTimeline ? result.name : result.note) || ''}</Popover.Content>
              </Popover>
            }
          >
            <div
              ref={measuredDescRef}
              className="multiple-line-overflow"
              dangerouslySetInnerHTML={{
                __html: getHighlightedText(
                  isTimeline ? result.name || '' : result.note || '',
                  filterVal,
                ),
              }}
            ></div>
          </OverlayTrigger>
        );
      }),
      onCustomSort: onCustomSortText,
      ...(sortMode && { sort: false }),
    },
    {
      id: 'fileName',
      Header: () => (
        <SortHeader
          text={isTimeline ? 'case.nameDocument' : 'case.name'}
          onSortDownClick={() => updateDocumentSort('fileName', 'asc', onCustomSortText)}
          onSortUpClick={() => updateDocumentSort('fileName', 'desc', onCustomSortText)}
          sortMode={sortMode}
        />
      ),
      Cell: (result: any) => (
        <span
          className="multiple-line-overflow"
          dangerouslySetInnerHTML={{
            __html: getHighlightedText(
              isTimeline ? (result.file && result.file.name) || '' : result.fileName || '',
              filterVal,
            ),
          }}
        ></span>
      ),
      minWidth: '150px',
      width: `18rem`,
      onCustomSort: onCustomSortText,
      ...(sortMode && { sort: false }),
    },
    isTimeline && {
      id: 'comment',
      Header: () => (
        <SortHeader
          text="case.comment"
          onSortDownClick={() => updateDocumentSort('comment', 'asc', onCustomSortText)}
          onSortUpClick={() => updateDocumentSort('comment', 'desc', onCustomSortText)}
          sortMode={sortMode}
        />
      ),
      Cell: (result: any) => (
        <OverlayTrigger
          placement="left"
          delay={{ show: 500, hide: 400 }}
          trigger={result.comment ? ['hover', 'focus'] : []}
          overlay={
            <Popover id="comment" style={{ pointerEvents: 'none' }}>
              <Popover.Content>{result.comment || ''}</Popover.Content>
            </Popover>
          }
        >
          <span
            className="multiple-line-overflow"
            dangerouslySetInnerHTML={{
              __html: getHighlightedText(result.comment || '', filterVal),
            }}
          ></span>
        </OverlayTrigger>
      ),
      minWidth: '160px',
      width: `calc(100% - 70rem)`,
      onCustomSort: onCustomSortText,
      ...(sortMode && { sort: false }),
    },
    // isTimeline && {
    //   id: 'fileText',
    //   Header: () => (
    //     <SortHeader
    //       text="case.selectedText"
    //       onSortDownClick={() => updateDocumentSort('fileText', 'asc', onCustomSortText)}
    //       onSortUpClick={() => updateDocumentSort('fileText', 'desc', onCustomSortText)}
    //       sortMode={sortMode}
    //     />
    //   ),
    //   minWidth: '250px',
    //   width: `calc(100% - 93rem)`,
    //   Cell: withResizeDetector(({ width, height, ...result }: any) => {
    //     const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
    //     const measuredDescRef = useRef<HTMLDivElement>(null);

    //     useEffect(() => {
    //       setShouldShowTooltip(
    //         measuredDescRef.current !== null &&
    //           measuredDescRef.current.offsetHeight < measuredDescRef.current.scrollHeight,
    //       );
    //     }, [width, height]);

    //     return (
    //       <OverlayTrigger
    //         placement="left"
    //         delay={{ show: 500, hide: 400 }}
    //         trigger={shouldShowTooltip ? ['hover', 'focus'] : []}
    //         overlay={
    //           <Popover id="fileText" style={{ pointerEvents: 'none' }}>
    //             <Popover.Content>{result.fileText || ''}</Popover.Content>
    //           </Popover>
    //         }
    //       >
    //         <div
    //           ref={measuredDescRef}
    //           className="multiple-line-overflow"
    //           dangerouslySetInnerHTML={{
    //             __html: getHighlightedText(result.fileText || '', filterVal),
    //           }}
    //         ></div>
    //       </OverlayTrigger>
    //     );
    //   }),
    //   onCustomSort: onCustomSortText,
    //   ...(sortMode && { sort: false }),
    // },
    !isTimeline && {
      id: 'createdBy',
      Header: () => (
        <SortHeader
          text="case.createdByName"
          onSortDownClick={() => updateDocumentSort('createdBy', 'asc', onCustomSortCreatedByName)}
          onSortUpClick={() => updateDocumentSort('createdBy', 'desc', onCustomSortCreatedByName)}
          sortMode={sortMode}
        />
      ),
      Cell: (result: any) => {
        const row = isAllAnnotations || isTimeline ? result : result.annotation;
        return (
          <span
            className="multiple-line-overflow"
            dangerouslySetInnerHTML={{
              __html: getHighlightedText((row.createdBy && row.createdBy.name) || '', filterVal),
            }}
          ></span>
        );
      },
      minWidth: '160px',
      width: `15rem`,
      onCustomSort: onCustomSortCreatedByName,
      ...(sortMode && { sort: false }),
    },
    !isTimeline && {
      Header: () => <SortHeader text="case.info" sortMode={false} />,
      Cell: (result: any) => (
        <Row style={{ width: '100%', marginRight: '-2px', marginLeft: '-2px' }}>
          {result.shared && (
            <Col
              style={{
                paddingRight: '2px',
                paddingLeft: '2px',
                marginRight: '0.5rem',
                flexGrow: 0,
              }}
            >
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Popover id="shared" style={{ maxWidth: '350px', fontSize: '0.75rem' }}>
                    <React.Fragment>
                      <Popover.Content>{T.translate('case.annotationShared')}</Popover.Content>
                    </React.Fragment>
                  </Popover>
                }
              >
                <FontAwesomeIcon style={{ color: 'black' }} icon={faShareAlt} />
              </OverlayTrigger>
            </Col>
          )}
        </Row>
      ),
      id: 'info',
      minWidth: '120px',
      width: `10rem`,
      onCustomSort: (a: any, b: any, { shared, ...rest }: any, { shared: sharedB }: any) => {
        let comparison = 0;
        if (shared) ++comparison;
        if (sharedB) --comparison;

        return comparison;
      },
      ...(sortMode && { sort: false }),
    },
  ].filter(Boolean);
