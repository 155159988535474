/* eslint-disable @typescript-eslint/no-shadow */
import { useState, useCallback } from 'react';
import {
  parseCSV,
  removeTrailingSpacesFromFilesWithAndWithoutExtensions,
  validateDateField,
} from '../utils';
import { MappingProblem, ParsedTemplate } from '../types';
import { PROBLEM_CATEGORIES } from '../constants';
import T from 'i18n';

type RunValidationArgs = {
  documents: any[];
  selectedTemplate: ParsedTemplate | null;
  primaryKey: string;
  columnMappings: { [key: string]: string };
  setMappedRowsWithErrors?: any;
  errors?: any;
};

export function useCSVMapping() {
  const [mappingFile, setMappingFile] = useState<File | null>(null);
  const [parsedData, setParsedData] = useState<any[]>([]);
  const [columns, setColumns] = useState<string[]>([]);

  const [mappingData, setMappingData] = useState<any[]>([]);
  const [failedRows, setFailedRows] = useState<MappingProblem[]>([]);

  const [validationErrors, setValidationErrors] = useState<{ [key: string]: boolean }>({});

  const parseFile = useCallback((file: File) => {
    const reader = new FileReader();
    reader.onload = event => {
      const text = event.target?.result;
      if (typeof text === 'string') {
        const data = parseCSV(text);
        const dataWithRowNumbers = data.map((row, index) => ({
          ...row,
          __rowNum: index + 2,
        }));
        setParsedData(dataWithRowNumbers);
        setColumns(Object.keys(data[0] || {}).filter(key => key !== '__rowNum'));
      }
    };
    reader.readAsText(file);
  }, []);

  const runValidationInHook = useCallback(
    (args: RunValidationArgs) => {
      const {
        documents,
        selectedTemplate,
        primaryKey,
        columnMappings,
        setMappedRowsWithErrors,
        errors = {},
      } = args;

      setFailedRows([]);
      setMappingData([]);
      if (setMappedRowsWithErrors) {
        setMappedRowsWithErrors([]);
      }

      selectedTemplate?.requiredFields?.forEach(field => {
        if (!columnMappings[field]) {
          errors[field] = true;
        }
      });
      if (!primaryKey) {
        errors.primaryKey = true;
      }
      setValidationErrors(errors);

      const invalidRows: MappingProblem[] = [];

      const csvDataMap: { [key: string]: any } = {};
      parsedData.forEach(row => {
        const keyVal = removeTrailingSpacesFromFilesWithAndWithoutExtensions(row[primaryKey]);
        if (keyVal) {
          csvDataMap[keyVal] = row;
        } else {
          invalidRows.push({
            rowNumber: row.__rowNum,
            reason: T.translate('case.batchUpload.dataMapper.reasons.missingPrimaryKey', {
              row: row.__rowNum,
            }),
            columnName: primaryKey,
            type: 'error',
            problemCategory: PROBLEM_CATEGORIES.missingPrimaryKey,
          });
        }
      });

      const processedData: any[] = [];
      const lookUpKeys: string[] = [];

      documents.forEach(doc => {
        if (!selectedTemplate) return;

        let documentKey = removeTrailingSpacesFromFilesWithAndWithoutExtensions(
          doc[selectedTemplate.fileIdentifier],
        );

        if (documentKey) {
          lookUpKeys.push(documentKey);
        } else {
          invalidRows.push({
            rowNumber: null,
            reason: `File identifier missing for doc ${doc.name}`,
            type: 'error',
            problemCategory: PROBLEM_CATEGORIES.missingFileIdentifier,
          });
          return;
        }

        let csvRow = csvDataMap[documentKey];
        if (!csvRow) {
          const dotIndex = documentKey.lastIndexOf('.');
          const fileNameWithoutExt =
            dotIndex !== -1 ? documentKey.substring(0, dotIndex).trim() : documentKey;
          csvRow = csvDataMap[fileNameWithoutExt];
        }
        if (!csvRow) {
          invalidRows.push({
            rowNumber: null,
            reason: T.translate('case.batchUpload.dataMapper.reasons.notFoundInCSV', {
              fileName: doc.name,
              columnName: selectedTemplate.fileIdentifier,
            }),
            type: 'error',
            problemCategory: PROBLEM_CATEGORIES.notFoundInCSV,
            fileName: doc.name,
          });
          if (setMappedRowsWithErrors) {
            setMappedRowsWithErrors((prev: any[]) => [...prev, doc.name]);
          }
          return;
        }

        const mappedObject: any = {};
        selectedTemplate.requiredFields?.forEach(field => {
          const colName = columnMappings[field];
          const value = csvRow[colName];
          mappedObject[field] = value;
          mappedObject['rowNumber'] = csvRow.__rowNum;

          if (value == null || value === '') {
            invalidRows.push({
              rowNumber: csvRow.__rowNum,
              reason: T.translate('case.batchUpload.dataMapper.reasons.missingRequiredField', {
                columnName: field,
              }),
              columnName: field,
              type: 'warning',
              problemCategory: PROBLEM_CATEGORIES.missingRequiredField,
            });
          } else {
            validateDateField(
              field,
              value,
              selectedTemplate.formatsForDates,
              csvRow,
              colName,
              invalidRows,
            );
          }

          mappedObject['__primaryKey'] = doc[selectedTemplate.fileIdentifier] ?? doc.name;
        });

        processedData.push(mappedObject);
      });

      Object.keys(csvDataMap).forEach(key => {
        if (!lookUpKeys.includes(key)) {
          const rowNum = csvDataMap[key].__rowNum;
          invalidRows.push({
            rowNumber: rowNum,
            reason: T.translate('case.batchUpload.dataMapper.reasons.orphanRow', { row: rowNum }),
            type: 'error',
            problemCategory: PROBLEM_CATEGORIES.orphanRow,
          });
        }
      });

      setFailedRows(invalidRows);
      setMappingData(processedData);

      return processedData;
    },
    [parsedData],
  );

  return {
    mappingFile,
    setMappingFile,

    parsedData,
    setParsedData,

    columns,
    setColumns,

    validationErrors,
    mappingData,
    setMappingData,
    failedRows,
    setFailedRows,

    parseFile,
    runValidationInHook,
  };
}
