import React, { useEffect, useState } from 'react';
import { IconButton, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { setSidebarOpen } from 'features/common/redux/navbarLayout';
import DocSummary from 'features/case/SearchCenter/DocSummary';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { setAiToolsFilters } from 'features/case/redux/setAiToolsFilters';
import history from 'common/history';
import { useSelector } from 'react-redux';
import {
  selectCurrentCase,
  selectCurrentFolder,
  selectPermissions,
  selectUserAiTools,
} from 'common/selectors';
import { Modal, Spinner } from 'features/common';
import T from 'i18n';
import api from 'common/api';
import { CASE_UPDATE_DOCUMENT_SUCCESS } from 'features/case/redux/constants';

type SideSummaryDisplayProps = {
  open: boolean;
  fileId: string;
  fileName: string;
  setLocalSidebarOpen: any;
  fileHasSummary: boolean;
};

const DeleteSummaryModal = ({ handleClose, removeSummary }: any) => {
  return (
    <Modal
      show={true}
      title={T.translate('case.deleteSummary')}
      handleClose={() => handleClose()}
      handleSave={() => {
        removeSummary();
        handleClose();
      }}
      saveTitle={T.translate('generic.delete')}
      className={undefined}
      wide={undefined}
      closeTitle={undefined}
      hintContent={undefined}
    >
      <Typography>{T.translate('case.deleteSummaryMsg')}</Typography>
    </Modal>
  );
};

const SideSummaryDisplay = ({
  open,
  fileId,
  fileName,
  setLocalSidebarOpen,
  fileHasSummary,
}: SideSummaryDisplayProps) => {
  const dispatch = useDispatch();
  const [showDeleteSummaryModal, setShowDeleteSummaryModal] = React.useState(false);
  const [summaries, setSummaries] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [rulings, setRulings] = useState([]);
  const [loading, setLoading] = useState(false);
  const showAiTools = useSelector(selectUserAiTools);
  const currentCase = useSelector(selectCurrentCase);
  const currentFolder = useSelector(selectCurrentFolder);

  const {
    ai: { deleteSummary },
  } = useSelector(selectPermissions);

  const handleAiToolsClicked = (files: any) => {
    dispatch(
      setAiToolsFilters({ files: files.map((file: any) => ({ id: file.id, name: file.name })) }),
    );
    history.push(
      history.location.pathname.replace(/\/case.*/, ``) + `/case/${currentCase.id}/ai-tools`,
    );
  };

  const fetchSummariesForDoc = async () => {
    try {
      if (fileId && currentCase?.id && fileHasSummary) {
        setLoading(true);
        const report: any = await api.get(
          `/cases/${currentCase.id}/files/${fileId}/summary/transcript`,
        );
        setSummaries(report?.summary);
        setSubmissions(report?.submissions);
        setRulings(report?.rulings);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('error', error);
    }
  };

  const removeSummary = async () => {
    try {
      if (fileId && currentCase?.id) {
        await api.del(`/cases/${currentCase.id}/files/${fileId}/summary/${fileId}-transcript`);
        setSummaries([]);
        setSubmissions([]);
        setRulings([]);
        dispatch({
          type: CASE_UPDATE_DOCUMENT_SUCCESS,
          data: {
            data: { hasSummary: false },
            fileId,
            zeroBasedIndex: currentFolder.zeroBasedIndex,
          },
        });
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const closeSummaryBar = (e: any) => {
    if (setLocalSidebarOpen && e.key === 'Escape') {
      dispatch(setSidebarOpen(true));
      setLocalSidebarOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', closeSummaryBar);
    fetchSummariesForDoc();
    return () => {
      window.removeEventListener('keydown', closeSummaryBar);
    };
  }, []);
  if (!open) return null;
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          top: 0,
          right: 0,
          width: setLocalSidebarOpen ? '20vw' : '100%',
          height: '100%',
          backgroundColor: 'background.paper',
          boxShadow: 3,
          display: 'flex',
          flexDirection: 'column',
          transition: 'width 1s ease-out',
        }}
      >
        {showAiTools ? (
          <>
            {setLocalSidebarOpen && (
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
                <Typography variant="h6">Summaries</Typography>
                <Box>
                  {deleteSummary &&
                    (summaries?.length > 0 || submissions?.length > 0 || rulings?.length > 0) && (
                      <IconButton
                        onClick={e => {
                          e.stopPropagation();
                          setShowDeleteSummaryModal(true);
                        }}
                        size="small"
                        sx={{ color: 'red' }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    )}

                  <IconButton
                    onClick={() => {
                      dispatch(setSidebarOpen(true)); // global
                      setLocalSidebarOpen(false); // local
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            )}
            <PerfectScrollbar
              style={{
                flexGrow: 1,
                padding: '0 16px 16px 16px',
                minHeight: '100px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {loading ? (
                <Spinner />
              ) : summaries?.length > 0 || submissions?.length > 0 || rulings?.length > 0 ? (
                <DocSummary
                  summary={summaries}
                  submissions={submissions}
                  rulings={rulings}
                  scrollBarRef={null}
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    No summaries available.
                  </Typography>
                  <Typography
                    sx={{
                      textDecoration: 'underline',
                      textAlign: 'center',
                      color: '#748CEC',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleAiToolsClicked([{ id: fileId, name: fileName }])}
                  >
                    Click to generate a summary
                  </Typography>
                </div>
              )}
            </PerfectScrollbar>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Typography
              sx={{
                textAlign: 'center',
              }}
            >
              You do not have access to AI Summaries
            </Typography>
          </div>
        )}
      </Box>
      {showDeleteSummaryModal && (
        <DeleteSummaryModal
          handleClose={() => setShowDeleteSummaryModal(false)}
          removeSummary={removeSummary}
        />
      )}
    </>
  );
};

export default SideSummaryDisplay;
