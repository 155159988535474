import { useCallback, useEffect, useRef, useState } from 'react';
import { getScrollContainer } from '../utils';
import { SELECTION_COLOR } from '../constants';

export const useScrollToHighlight = ({ viewerControl }: any) => {
  const [scrollToHighlight, setScrollToHighlight] = useState<any>(null);
  const oldMarkToBeUpdatedColorRef = useRef<any>(null);

  const clearScrollToHighlight = useCallback(() => {
    if (scrollToHighlight) {
      const mark = viewerControl
        .getAllMarks()
        .find((x: any) => x.uid === scrollToHighlight.annotation.uid);
      if (mark) {
        mark.fillColor = oldMarkToBeUpdatedColorRef.current;
        oldMarkToBeUpdatedColorRef.current = null;
      }
      setScrollToHighlight(null);
    }
  }, [scrollToHighlight, viewerControl]);

  const scrollToHighlightHandler = (highlight: any) => {
    clearScrollToHighlight();

    const mark = viewerControl.getAllMarks().find((x: any) => x.uid === highlight.annotation.uid);

    const scrollToMark = (mark: any) => {
      oldMarkToBeUpdatedColorRef.current = mark.fillColor;
      mark.setFillColor(SELECTION_COLOR);
      setScrollToHighlight(highlight);
      viewerControl.scrollToAsync(mark);
    };

    if (mark) {
      scrollToMark(mark);
    } else {
      if (highlight?.annotation?.pageNumber) {
        viewerControl
          .scrollToAsync({
            pageNumber: highlight.annotation.pageNumber,
            x: highlight.annotation?.rectangle?.x || 500,
            y: highlight.annotation?.rectangle?.y || 500,
          })
          .then(() => {
            setTimeout(() => {
              const mark = viewerControl
                ?.getAllMarks()
                .find((x: any) => x.uid === highlight.annotation.uid);
              if (mark) {
                scrollToMark(mark);
              }
            }, 250);
          });
      }
    }
  };

  useEffect(() => {
    getScrollContainer(viewerControl)?.addEventListener('mousedown', clearScrollToHighlight);

    return () => {
      getScrollContainer(viewerControl)?.removeEventListener('mousedown', clearScrollToHighlight);
    };
  }, [clearScrollToHighlight, viewerControl]);

  return {
    scrollSelectedHighlight: scrollToHighlight,
    scrollToHighlight: scrollToHighlightHandler,
  };
};
