import React, { useEffect, useState } from 'react';
import T from 'i18n';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectConfidentialityRing, selectCurrentFolder } from 'common/selectors';
import { useForm } from 'react-hook-form';
import * as actions from '../../redux/actions';
import { Spinner, TextForm } from 'features/common';
import { Col, Form, Row } from 'react-bootstrap';
import { Modal } from 'features/common/MaterialBasedComponents';

const ModalReplace = ({ show, handleClose, handleSave, action, selectedRows }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const isConfidentialityRing = useSelector(selectConfidentialityRing);
  const currentFolder = useSelector(selectCurrentFolder);
  const [loading, setLoading] = useState(false);
  const [fileVariants, setFileVariants] = useState(null);
  const file = selectedRows[0] || {};
  const { originalName, name } = file;

  useEffect(() => {
    if (isConfidentialityRing && !fileVariants) {
      setLoading(true);
      dispatch(actions.fetchFileRings({ fileId: selectedRows[0].id }))
        .then(res => setFileVariants(res))
        .finally(() => setLoading(false));
    }
  }, [selectedRows, dispatch, fileVariants, isConfidentialityRing]);

  return (
    <Modal
      show={show}
      title={T.translate('generic.replaceFile')}
      handleClose={handleClose}
      handleSave={handleSubmit(data => {
        setLoading(true);
        handleSave(currentFolder.id, action, selectedRows, data, handleClose, setLoading);
      })}
      saveTitle={T.translate('generic.replace')}
      disableSave={loading}
    >
      {loading ? (
        <Spinner />
      ) : isConfidentialityRing && fileVariants && fileVariants.length > 1 ? (
        <div>{T.translate('case.replaceModalMultipleFileVariantsMsg')}</div>
      ) : (
        <>
          <div>
            {T.translate('case.replaceModalMsg', {
              documentName: name || originalName,
              folder: currentFolder.name || 'all documents',
            })}
          </div>
          <br />
          <Form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <Form.Group>
              <Row>
                <Col>
                  <TextForm
                    {...register('newFile', { required: true })}
                    type="file"
                    errors={errors}
                  />
                </Col>
                <Col />
                <Col />
              </Row>
            </Form.Group>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default ModalReplace;
