import React from 'react';
import T from 'i18n';
import moment from 'moment';
import FilterNameChip from './FilterNameChip';
import { useSelector } from 'react-redux';
import { selectFilterOptions } from 'common/selectors';
import { FilterArrayChip } from './FilterArrayChip';

const getCreatedByNameFromId = (filterOptions: any, id: string) => {
  const options = filterOptions.find((option: any) => option.filterKey === 'createdBy');

  if (!options) return id;

  const createdBy = options.originalArray.find((item: any) => item.id === id);

  return createdBy ? createdBy.name : '';
};

const getTagNameFromId = (filterOptions: any, id: string) => {
  const options = filterOptions.find((option: any) => option.filterKey === 'tags');

  if (!options) return id;

  const tag = options.originalArray.find((item: any) => item.id === id);

  return tag ? tag.name : '';
};

const getFolderNameFromId = (filterOptions: any, id: string) => {
  const options = filterOptions.find((option: any) => option.filterKey === 'trialBundles');

  if (!options) return id;

  const folder = options.originalArray.find((item: any) => item.id === id);

  return folder ? folder.name : '';
};

export default ({ filterCategory, clearFilter }: any) => {
  const filterOptions = useSelector(selectFilterOptions);

  return (
    <>
      {filterCategory && filterCategory.dateFilter && filterCategory.dateFrom && (
        <FilterNameChip
          idx={`date-from-filter-chip`}
          label={`From ${moment(filterCategory.dateFrom).format('DD/MM/YYYY')}`}
          deleteHandler={() => {
            const clearDate = {
              dateFilter: !!filterCategory.dateTo,
              dateFrom: null,
            };
            clearFilter(false, { ...filterCategory, ...clearDate });
          }}
        />
      )}
      {filterCategory && filterCategory.dateFilter && filterCategory.dateTo && (
        <FilterNameChip
          idx={`date-to-filter-chip`}
          label={`To ${moment(filterCategory.dateTo).format('DD/MM/YYYY')}`}
          deleteHandler={() => {
            const clearDate = {
              dateFilter: !!filterCategory.dateFrom,
              dateTo: null,
            };
            clearFilter(false, { ...filterCategory, ...clearDate });
          }}
        />
      )}
      {filterCategory && filterCategory.withAnnotations && (
        <FilterNameChip
          idx={`chip-public`}
          label={T.translate('filters.withAnnotations')}
          deleteHandler={() => clearFilter(false, { ...filterCategory, withAnnotations: false })}
        />
      )}
      {filterCategory && filterCategory.withoutAnnotations && (
        <FilterNameChip
          idx={`chip-public`}
          label={T.translate('filters.withoutAnnotations')}
          deleteHandler={() => clearFilter(false, { ...filterCategory, withoutAnnotations: false })}
        />
      )}
      {filterCategory && filterCategory.public && (
        <FilterNameChip
          idx={`chip-public`}
          label={T.translate('case.visibilityPublicHeading')}
          deleteHandler={() => clearFilter(false, { ...filterCategory, public: false })}
        />
      )}
      {filterCategory && filterCategory.private && (
        <FilterNameChip
          idx={`chip-private`}
          label={T.translate('case.visibilityPrivateHeading')}
          deleteHandler={() => clearFilter(false, { ...filterCategory, private: false })}
        />
      )}
      {filterCategory && filterCategory.withPublicHyperlinks && (
        <FilterNameChip
          idx={`chip-withPublicHyperlinks`}
          label={T.translate('case.publicHyperlinksHeading')}
          deleteHandler={() =>
            clearFilter(false, { ...filterCategory, withPublicHyperlinks: false })
          }
        />
      )}
      {filterCategory && filterCategory.withoutPublicHyperlinks && (
        <FilterNameChip
          idx={`chip-withoutPublicHyperlinks`}
          label={T.translate('case.noPublicHyperlinksHeading')}
          deleteHandler={() =>
            clearFilter(false, { ...filterCategory, withoutPublicHyperlinks: false })
          }
        />
      )}
      {filterCategory && filterCategory.shared && (
        <FilterNameChip
          idx={`chip-shared`}
          label={T.translate('filters.shared')}
          deleteHandler={() => clearFilter(false, { ...filterCategory, shared: false })}
        />
      )}
      {filterCategory && filterCategory.notShared && (
        <FilterNameChip
          idx={`chip-shared`}
          label={T.translate('filters.notShared')}
          deleteHandler={() => clearFilter(false, { ...filterCategory, notShared: false })}
        />
      )}
      {filterCategory && filterCategory.authors.length > 0 && (
        <FilterArrayChip
          label={T.translate('case.authorHeading')}
          items={filterCategory.authors}
          deleteHandler={(author: any) => {
            const filterAuthors = filterCategory.authors.filter((item: any) => author !== item);
            clearFilter(false, { ...filterCategory, authors: filterAuthors });
          }}
        />
      )}
      {filterCategory && filterCategory.recipients.length > 0 && (
        <FilterArrayChip
          label={T.translate('case.recipientHeading')}
          items={filterCategory.recipients}
          deleteHandler={(recipient: any) => {
            const filterRecipients = filterCategory.recipients.filter(
              (item: any) => recipient !== item,
            );
            clearFilter(false, { ...filterCategory, recipients: filterRecipients });
          }}
        />
      )}
      {filterCategory && filterCategory.createdBy.length > 0 && (
        <FilterArrayChip
          label={T.translate('case.createdByHeading')}
          items={filterCategory.createdBy}
          labelFunction={(id: any) => {
            return getCreatedByNameFromId(filterOptions, id);
          }}
          deleteHandler={(createdBy: any) => {
            const filteredCreatedBy = filterCategory.createdBy.filter(
              (item: any) => createdBy !== item,
            );
            clearFilter(false, { ...filterCategory, createdBy: filteredCreatedBy });
          }}
        />
      )}
      {filterCategory && filterCategory.tags.length > 0 && (
        <FilterArrayChip
          label={T.translate('case.tagsHeading')}
          items={filterCategory.tags}
          labelFunction={(id: any) => {
            return getTagNameFromId(filterOptions, id);
          }}
          deleteHandler={(tag: any) => {
            const filterTags = filterCategory.tags.filter((item: any) => tag !== item);
            clearFilter(false, { ...filterCategory, tags: filterTags });
          }}
        />
      )}

      {filterCategory && filterCategory.trialBundles.length > 0 && (
        <FilterArrayChip
          label={T.translate('case.trialBundlesHeading')}
          items={filterCategory.trialBundles}
          labelFunction={(id: any) => {
            return getFolderNameFromId(filterOptions, id);
          }}
          deleteHandler={(trialBundle: any) => {
            const filteredBundles = filterCategory.trialBundles.filter(
              (item: any) => trialBundle !== item,
            );
            clearFilter(false, { ...filterCategory, trialBundles: filteredBundles });
          }}
        />
      )}
      {filterOptions
        .filter(
          (option: any) =>
            !['createdBy', 'authors', 'recipients', 'tags', 'trialBundles'].includes(
              option.filterKey,
            ),
        )
        .map((option: any) => {
          return filterCategory &&
            filterCategory[option.filterKey] &&
            filterCategory[option.filterKey].length > 0 ? (
            <FilterArrayChip
              label={option.filterKey}
              items={filterCategory[option.filterKey]}
              deleteHandler={(itm: any) => {
                const filtered = filterCategory[option.filterKey].filter(
                  (item: any) => itm !== item,
                );
                clearFilter(false, { ...filterCategory, [option.filterKey]: filtered });
              }}
            />
          ) : null;
        })}
    </>
  );
};
