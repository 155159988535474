import React from 'react';
import T from 'i18n';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { setHours, setMinutes, setSeconds, setMilliseconds, parse, isValid } from 'date-fns';
import logger from 'utils/logger';
import debounce from 'utils/debounce';
import { LocalizationProvider } from '@mui/x-date-pickers';
import DatePicker, { isValidDate } from './DatePicker';

export default ({
  filterCategory,
  setFilters,
}: {
  filterCategory: null | any;
  setFilters: (newFilterCategory: any) => void;
}) => {
  const handleUserTypedDate = (field: any) => (event: any) => {
    const typedDate = event.target.value;
    const parsedDate = parse(typedDate, 'dd/MM/yyyy', new Date());

    if (isValid(parsedDate)) {
      handleDateChange(field)(parsedDate);
    } else {
      logger.ERROR('Invalid date format.');
    }
  };

  const handleDateChange = (field: 'dateFrom' | 'dateTo') =>
    debounce((newValue: any) => {
      if (!newValue) {
        setFilters({
          ...filterCategory,
          [field]: null,
          dateFilter: !!(field === 'dateFrom' ? filterCategory?.dateTo : filterCategory?.dateFrom),
        });
        return;
      }

      if (!isValidDate(newValue)) return;

      const dateWithTimeReset = newValue
        ? setMilliseconds(setSeconds(setMinutes(setHours(newValue, 0), 0), 0), 0)
        : newValue;

      setFilters({
        ...filterCategory,
        [field]: dateWithTimeReset,
        dateFilter: !!(
          (field === 'dateFrom' ? filterCategory?.dateTo : filterCategory?.dateFrom) ||
          dateWithTimeReset
        ),
      });
    }, 1000);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid xs={3}>
        <Typography
          style={{
            marginLeft: 0,
            alignSelf: 'center',
            marginTop: '0.5rem',
            fontWeight: 'bold',
          }}
        >
          {T.translate('filters.dateFromTo')}
        </Typography>
      </Grid>
      <Grid xs={9}>
        <div style={{ display: 'flex' }}>
          <DatePicker
            value={filterCategory?.dateFrom && new Date(filterCategory?.dateFrom)}
            onChange={handleDateChange('dateFrom')}
            onBlur={handleUserTypedDate('dateFrom')}
            placeholder={T.translate('generic.from')}
          />
          <DatePicker
            value={filterCategory?.dateTo && new Date(filterCategory?.dateTo)}
            onChange={handleDateChange('dateTo')}
            onBlur={handleUserTypedDate('dateTo')}
            placeholder={T.translate('generic.to')}
            style={{ marginLeft: '1rem' }}
          />
        </div>
      </Grid>
    </LocalizationProvider>
  );
};
