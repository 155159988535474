/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  keyframes,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import T from 'i18n';
import api from 'common/api';
import { useSelector } from 'react-redux';
import { selectCurrentCaseId, selectUserId } from 'common/selectors';
import { useFetchAnnotations } from 'features/viewing/redux/fetchAnnotations';
import { HyperlinkRow } from '../../extraComponents/hyperlinks/HyperLinkRow';
import { ExistingHyperlinkRow } from '../../extraComponents/hyperlinks/ExistingHyperLinkRow';
import { Edit, EditOff, Undo } from '@mui/icons-material';
import { useUndoableList } from '../../hooks/useUndoableList';
import { Spinner } from 'features/common';
type HyperlinkCandidate = any;

type PreviewHyperlinksProps = {
  show: boolean;
  handleClose: () => void;
  selectedRows: any[];
  fromAllExternalIDs: boolean;
  fromPageRefs: boolean;
};

const underlineAnimation = keyframes`
  0% {
    text-decoration: underline;
  }
  75% {
    text-decoration: underline;
  }
  100% {
    text-decoration: none;
  }
`;
const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    borderBottom: '1px solid #ccc',
    textAlign: 'left',
    padding: '8px',
    position: 'sticky',
    top: 0,
    backgroundColor: '#f9f9f9',
    zIndex: 1,
  },
  td: {
    borderBottom: '1px solid #eee',
    padding: '8px',
  },
  existingTitle: {
    animation: `${underlineAnimation} 5s ease-in-out`,
  },
});

const PreviewHyperlinks: React.FC<PreviewHyperlinksProps> = ({
  show,
  handleClose,
  selectedRows,
  fromAllExternalIDs,
  fromPageRefs,
}) => {
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [showExisting, setShowExisting] = useState(false);
  const [existingHyperlinks, setExistingHyperlinks] = useState<any[]>([]);
  const [existingCount, setExistingCount] = useState(0);

  const [isEditing, setIsEditing] = useState(false);
  const [copyFeedback, setCopyFeedback] = useState<string | null>(null);
  const [copyFeedbackIndex, setCopyFeedbackIndex] = useState<number | null>(null);

  const [totalLinks, setTotalLinks] = useState<number>(0);
  const [createdByBreakdown, setCreatedByBreakdown] = useState<Record<string, number>>({});
  const [rawHyperlinks, setRawHyperlinks] = useState<any[]>([]);
  const currentUserId = useSelector(selectUserId);

  const { fetchAnnotations } = useFetchAnnotations();

  const classes = useStyles();

  const caseId = useSelector(selectCurrentCaseId);

  const file: any = selectedRows?.[selectedRows.length - 1];

  const {
    items: hyperlinks,
    setItems: setHyperlinks,
    handleDeleteRow,
    handleUndoDelete,
    undoStack,
  } = useUndoableList<any>([]);

  const fetchHyperlinks = async () => {
    if (!show) return;
    if (!file?.id || !caseId) {
      setFetchError('Missing file or case ID');
      return;
    }

    setLoading(true);
    setFetchError(null);

    try {
      const response: any = await api.get(
        `/cases/${caseId}/files/${file.id}/hyperlink-candidates?fromAllExternalIDs=${fromAllExternalIDs}&fromPageRefs=${fromPageRefs}`,
      );
      setRawHyperlinks(response);
      const annotations = response;

      const newHyperlinks: HyperlinkCandidate[] =
        annotations.length > 0
          ? annotations?.map((ann: any) => {
              return {
                sourcePage: ann.sourcePage,
                sourceText: ann.sourceText,
                targetDocId: ann.targetDocId,
                targetFilename: ann.targetFilename,
                targetPage: ann.targetPage,
                href: ann.href,
              };
            })
          : [];
      newHyperlinks.sort((a, b) => {
        if (a.sourcePage < b.sourcePage) {
          return -1;
        }
        if (a.sourcePage > b.sourcePage) {
          return 1;
        }
        return 0;
      });
      const filteredHyperlinks = newHyperlinks.filter(
        link =>
          link.sourcePage ||
          link.sourceText ||
          link.targetDocId ||
          link.targetFilename ||
          link.targetPage,
      );
      setHyperlinks(filteredHyperlinks);

      const total = newHyperlinks.length;
      setTotalLinks(total);

      const byUser: Record<string, number> = {};
      newHyperlinks.forEach(h => {
        if (h.createdBy) {
          byUser[h.createdBy] = (byUser[h.createdBy] || 0) + 1;
        }
      });
      setCreatedByBreakdown(byUser);

      setLoading(false);
    } catch (err) {
      if (err instanceof Error) {
        setFetchError(err.message);
      } else {
        setFetchError('An unknown error occurred');
      }
      setLoading(false);
    }
  };

  const handleCreateHyperlinks = async () => {
    setLoading(true);
    setFetchError(null);

    try {
      await api.post(
        `/cases/${caseId}/files/${file.id}/hyperlinks?overwriteExistingHyperlinks=${showExisting}`,
        rawHyperlinks,
      );
      handleClose();
    } catch (err) {
      if (err instanceof Error) {
        setFetchError(err.message);
      } else {
        setFetchError('Unknown error occurred');
      }
      setLoading(false);
    }

    setLoading(false);
  };

  const getExisting = async () => {
    let existing = await fetchAnnotations({
      file: file.id,
    });
    existing.filter((ann: any) => ann.annotation.type === 'TextHyperlinkAnnotation');
    if (fromAllExternalIDs) {
      existing = existing.sort((a: any, b: any) => {
        return a.annotation.sourcePage - b.annotation.sourcePage;
      });
    } else {
      existing = existing.sort((a: any, b: any) => {
        return a.annotation.pageNumber - b.annotation.pageNumber;
      });
    }
    if (existing) {
      setExistingHyperlinks(existing);

      const existingHyperlinksCreatedByMe = existing.filter(
        (ann: any) => ann?.createdBy?.id === currentUserId,
      );
      setExistingCount(existingHyperlinksCreatedByMe.length);
    }
  };

  const clearAll = () => {
    setHyperlinks([]);
    setFetchError(null);
    setLoading(false);
    setShowExisting(false);
    setExistingHyperlinks([]);
    setIsEditing(false);
    setTotalLinks(0);
    setCreatedByBreakdown({});
    setRawHyperlinks([]);
  };

  const onClose = () => {
    clearAll();
    handleClose();
  };

  useEffect(() => {
    getExisting();
  }, [show, caseId, file]);

  useEffect(() => {
    fetchHyperlinks();
  }, [show, caseId, file]);

  useEffect(() => {
    if (copyFeedbackIndex !== null && copyFeedback) {
      const timer = setTimeout(() => {
        setCopyFeedback(null);
        setCopyFeedbackIndex(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [copyFeedbackIndex, copyFeedback]);

  return (
    <Dialog open={show} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        {T.translate('hyperlinks.preview.title', {
          defaultValue: 'Preview Hyperlinks',
        })}
        <IconButton onClick={() => setIsEditing(!isEditing)}>
          {isEditing ? <EditOff sx={{ color: '#ECD474' }} /> : <Edit />}
        </IconButton>
        {undoStack?.length > 0 && (
          <IconButton onClick={handleUndoDelete} title="Undo last deletion">
            <Undo />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={classes.tableContainer}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
            <Spinner />
          </Box>
        ) : fetchError ? (
          <Typography variant="body1" color="error">
            {fetchError}
          </Typography>
        ) : hyperlinks.length === 0 ? (
          <Typography variant="body1" color="textSecondary">
            {T.translate('hyperlinks.preview.noData', {
              defaultValue: 'No hyperlink candidates.',
            })}
          </Typography>
        ) : (
          <table className={classes.table}>
            <thead>
              <tr>
                {isEditing && (
                  <th className={classes.th} style={{ width: '40px', textAlign: 'center' }}>
                    {T.translate('hyperlinks.preview.deleteColumnHeader', { defaultValue: '' })}
                  </th>
                )}
                <th className={classes.th}>
                  {T.translate('hyperlinks.preview.sourcePageHeader', {
                    defaultValue: 'Source Page',
                  })}
                </th>
                <th className={classes.th}>
                  {T.translate('hyperlinks.preview.sourceTextHeader', {
                    defaultValue: 'Source Text',
                  })}
                </th>
                <th className={classes.th}>
                  {T.translate('hyperlinks.preview.targetdocIdHeader', {
                    defaultValue: 'Target Doc Id',
                  })}
                </th>
                <th className={classes.th}>
                  {T.translate('hyperlinks.preview.targetPageHeader', {
                    defaultValue: 'Target Page',
                  })}
                </th>
                <th className={classes.th}>
                  {T.translate('hyperlinks.preview.targetFilenameHeader', {
                    defaultValue: 'Target FileName',
                  })}
                </th>
              </tr>
            </thead>
            <tbody>
              {hyperlinks.map((link, index) => (
                <HyperlinkRow
                  key={index}
                  link={link}
                  classes={classes}
                  handleDeleteRow={handleDeleteRow}
                  index={index}
                  isEditing={isEditing}
                />
              ))}
            </tbody>
          </table>
        )}

        {hyperlinks.length > 0 && !loading && (
          <Box mt={2}>
            <Typography variant="body2">
              {T.translate('hyperlinks.preview.totalLinks', {
                defaultValue: 'About to create {{totalLinks}} NEW hyperlinks.',
                totalLinks,
              })}
            </Typography>
            {Object.keys(createdByBreakdown).length > 0 && (
              <Box mt={1}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {T.translate('hyperlinks.preview.breakdownTitle', {
                    defaultValue: 'Breakdown by user',
                  })}
                </Typography>
                {Object.entries(createdByBreakdown).map(([user, count]) => (
                  <Typography key={user} variant="body2">
                    {`${user}: ${count}`}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
        )}
        {existingHyperlinks.length > 0 && (
          <Box mt={2}>
            {showExisting && (
              <Box mt={2}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 'bold', mb: 1 }}
                  id="existingHyperlinksHeader"
                  className={classes.existingTitle}
                >
                  {T.translate('hyperlinks.preview.existingTitle', {
                    defaultValue: 'Existing Hyperlinks',
                  })}
                </Typography>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th className={classes.th}>
                        {T.translate('hyperlinks.preview.sourcePageHeader', {
                          defaultValue: 'Source Page',
                        })}
                      </th>
                      <th className={classes.th}>
                        {T.translate('hyperlinks.preview.sourceTextHeader', {
                          defaultValue: 'Source Text',
                        })}
                      </th>
                      <th className={classes.th}>
                        {T.translate('hyperlinks.preview.docIdHeader', {
                          defaultValue: 'Source Doc Id.',
                        })}
                      </th>
                      <th className={classes.th}>
                        {T.translate('hyperlinks.preview.createdBy', {
                          defaultValue: 'Created By',
                        })}
                      </th>
                      <th className={classes.th}>
                        {T.translate('hyperlinks.preview.linkPageHeader', {
                          defaultValue: 'Source FileName',
                        })}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {existingHyperlinks.map((ann, idx) => (
                      <ExistingHyperlinkRow key={idx} ann={ann} classes={classes} />
                    ))}
                  </tbody>
                </table>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {existingHyperlinks.length > 0 && (
          <Box display="flex" alignItems="center" alignSelf={'flex-start'} gap={1} mr="auto" ml={4}>
            <input
              type="checkbox"
              id="delete-hyperlinks"
              onChange={e => {
                setShowExisting(e.target.checked);
              }}
              checked={showExisting}
            />
            <label htmlFor="delete-hyperlinks" style={{ cursor: 'pointer', marginTop: 6 }}>
              {T.translate('hyperlinks.preview.deleteHyperlinks', {
                defaultValue: 'Delete my {{ existing }} existing hyperlinks?',
                existing: existingCount,
              })}
            </label>
          </Box>
        )}

        <Button onClick={onClose} sx={{ color: '#748CEC' }}>
          {T.translate('hyperlinks.preview.cancel', {
            defaultValue: 'Cancel',
          })}
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: '#748CEC' }}
          onClick={handleCreateHyperlinks}
          disabled={loading || hyperlinks.length === 0}
        >
          {T.translate('hyperlinks.preview.createButton', {
            defaultValue: 'Create',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewHyperlinks;
