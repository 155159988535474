/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Box,
  keyframes,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import T from 'i18n';
import api from 'common/api';
import { useSelector } from 'react-redux';
import { selectCurrentCaseId } from 'common/selectors';
import { useFetchAnnotations } from 'features/viewing/redux/fetchAnnotations';
import DatePicker from 'features/common/Filters/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { HyperlinkRow } from '../../extraComponents/hyperlinks/HyperLinkRow';
import { ExistingHyperlinkRow } from '../../extraComponents/hyperlinks/ExistingHyperLinkRow';
import { useUndoableList } from '../../hooks/useUndoableList';
import { Edit, EditOff, Undo } from '@mui/icons-material';

type ConcealedPreviewHyperlinksProps = {
  show: boolean;
  handleClose: () => void;
  selectedRows: any[];
};

const underlineAnimation = keyframes`
  0% {
    text-decoration: underline;
  }
  75% {
    text-decoration: underline;
  }
  100% {
    text-decoration: none;
  }
`;

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    borderBottom: '1px solid #ccc',
    textAlign: 'left',
    padding: '8px',
    position: 'sticky',
    top: 0,
    backgroundColor: '#f9f9f9',
    zIndex: 1,
  },
  td: {
    borderBottom: '1px solid #eee',
    padding: '8px',
  },
  existingTitle: {
    animation: `${underlineAnimation} 5s ease-in-out`,
  },
  shroudOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(240, 240, 240, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    pointerEvents: 'none',
  },
  shroudContainer: {
    position: 'relative',
  },
});

function toDayMonthYear(date: any) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const ConcealedPreviewHyperlinks: React.FC<ConcealedPreviewHyperlinksProps> = ({
  show,
  handleClose,
  selectedRows,
}) => {
  const classes = useStyles();
  const caseId = useSelector(selectCurrentCaseId);

  const { fetchAnnotations } = useFetchAnnotations();
  const file: any = selectedRows?.[selectedRows.length - 1];

  const [loading, setLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<string | null>(null);

  const [showExisting, setShowExisting] = useState(false);
  const [existingHyperlinks, setExistingHyperlinks] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState(false);

  const [copyFeedback, setCopyFeedback] = useState<string | null>(null);
  const [copyFeedbackIndex, setCopyFeedbackIndex] = useState<number | null>(null);

  const [totalLinks, setTotalLinks] = useState<number>(0);
  const [createdByBreakdown, setCreatedByBreakdown] = useState<Record<string, number>>({});
  const [rawHyperlinks, setRawHyperlinks] = useState<any[]>([]);

  const [transcriptDate, setTranscriptDate] = useState<Date | null>(null);
  const [hasConfirmed, setHasConfirmed] = useState<boolean>(false);
  const {
    items: hyperlinks,
    setItems: setHyperlinks,
    handleDeleteRow,
    handleUndoDelete,
    undoStack,
  } = useUndoableList<any>([]);

  const getExistingHyperlinks = async () => {
    if (!file?.id || !caseId) return;
    let existing = await fetchAnnotations({ file: file.id });
    existing = existing.filter((ann: any) => ann.annotation.type === 'TextHyperlinkAnnotation');
    existing = existing.sort((a: any, b: any) => {
      return a.annotation.pageNumber - b.annotation.pageNumber;
    });
    setExistingHyperlinks(existing);
  };

  const fetchTranscriptHyperlinks = async (date: Date) => {
    if (!file?.id || !caseId) {
      setFetchError('Missing file or case ID');
      return;
    }

    setLoading(true);
    setFetchError(null);

    try {
      const formattedDate = toDayMonthYear(date);
      const response: any = await api.get(
        `/cases/${caseId}/files/${file.id}/hyperlink-candidates?isTranscript=true&transcriptDate=${formattedDate}`,
      );

      setRawHyperlinks(response);

      const newHyperlinks = response.map((ann: any) => {
        return {
          sourcePage: ann.sourcePage,
          sourceText: ann.sourceText,
          targetDocId: ann.targetDocId,
          targetFilename: ann.targetFilename,
          targetPage: ann.targetPage,
          href: ann.href,
          createdBy: ann.createdBy,
        };
      });

      newHyperlinks.sort((a: any, b: any) => {
        if (a.sourcePage < b.sourcePage) return -1;
        if (a.sourcePage > b.sourcePage) return 1;
        return 0;
      });

      const filteredHyperlinks = newHyperlinks.filter(
        (link: any) =>
          link.sourcePage ||
          link.sourceText ||
          link.targetDocId ||
          link.targetFilename ||
          link.targetPage,
      );
      setHyperlinks(filteredHyperlinks);

      setTotalLinks(filteredHyperlinks.length);

      const byUser: Record<string, number> = {};
      filteredHyperlinks.forEach((h: any) => {
        if (h.createdBy) {
          byUser[h.createdBy] = (byUser[h.createdBy] || 0) + 1;
        }
      });
      setCreatedByBreakdown(byUser);
    } catch (err) {
      if (err instanceof Error) {
        setFetchError(err.message);
      } else {
        setFetchError('An unknown error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCreateHyperlinks = async () => {
    if (!file?.id || !caseId) return;

    setLoading(true);
    setFetchError(null);

    try {
      await api.post(
        `/cases/${caseId}/files/${file.id}/hyperlinks?overwriteExistingHyperlinks=${showExisting}`,
        rawHyperlinks,
      );
      handleClose();
    } catch (err) {
      if (err instanceof Error) {
        setFetchError(err.message);
      } else {
        setFetchError('Unknown error occurred');
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const handleConfirmDate = () => {
    setFetchError(null);

    if (!transcriptDate) {
      setFetchError('Please select a transcript date first.');
      return;
    }
    setHasConfirmed(true);
    fetchTranscriptHyperlinks(transcriptDate);
  };

  const clearAll = () => {
    setHyperlinks([]);
    setFetchError(null);
    setLoading(false);
    setShowExisting(false);
    setExistingHyperlinks([]);
    setIsEditing(false);
    setTotalLinks(0);
    setCreatedByBreakdown({});
    setRawHyperlinks([]);
  };

  const onClose = () => {
    clearAll();
    handleClose();
  };

  useEffect(() => {
    if (show) {
      getExistingHyperlinks();
    }
  }, [show, caseId, file]);

  useEffect(() => {
    if (copyFeedbackIndex !== null && copyFeedback) {
      const timer = setTimeout(() => {
        setCopyFeedback(null);
        setCopyFeedbackIndex(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [copyFeedbackIndex, copyFeedback]);

  return (
    <Dialog open={show} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        {T.translate('hyperlinks.preview.title', {
          defaultValue: 'Preview Transcript-based Hyperlinks',
        })}
        <IconButton onClick={() => setIsEditing(!isEditing)}>
          {isEditing ? <EditOff sx={{ color: '#ECD474' }} /> : <Edit />}
        </IconButton>
        {undoStack?.length > 0 && (
          <IconButton onClick={handleUndoDelete} title="Undo last deletion">
            <Undo />
          </IconButton>
        )}
      </DialogTitle>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {T.translate('hyperlinks.preview.transcriptPrompt', {
                defaultValue: 'Please select a transcript date to unlock the preview.',
              })}
            </Typography>
            <Box display="flex" gap={2} mb={1}>
              <DatePicker
                label={T.translate('case.transcriptDate', { defaultValue: 'Transcript Date' })}
                value={transcriptDate}
                format="dd/MM/yyyy"
                onChange={(dateVal: any) => {
                  setTranscriptDate(dateVal);
                }}
              />
              <Button
                variant="contained"
                onClick={handleConfirmDate}
                sx={{ alignSelf: 'flex-end' }}
              >
                {T.translate('generic.confirm', {
                  defaultValue: 'Confirm',
                })}
              </Button>
            </Box>
            {fetchError && (
              <Typography variant="body1" color="error" sx={{ mb: 1 }}>
                {fetchError}
              </Typography>
            )}
          </Box>

          <Box className={classes.shroudContainer}>
            {!hasConfirmed && (
              <div className={classes.shroudOverlay}>
                <Typography variant="body2" color="textSecondary">
                  {T.translate('hyperlinks.preview.unlockPrompt', {
                    defaultValue: 'Confirm the transcript date to unlock the preview.',
                  })}
                </Typography>
              </div>
            )}

            <div className={classes.tableContainer} style={{ position: 'relative' }}>
              {!hasConfirmed || loading ? (
                loading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="80px"
                    mb={2}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th className={classes.th}>Source Page</th>
                        <th className={classes.th}>Source Text</th>
                        <th className={classes.th}>Target Doc Id</th>
                        <th className={classes.th}>Target Page</th>
                        <th className={classes.th}>Target Filename</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[1, 2, 3].map(i => (
                        <tr key={i}>
                          <td className={classes.td}>&nbsp;</td>
                          <td className={classes.td}>&nbsp;</td>
                          <td className={classes.td}>&nbsp;</td>
                          <td className={classes.td}>&nbsp;</td>
                          <td className={classes.td}>&nbsp;</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )
              ) : hyperlinks.length === 0 ? (
                <Typography variant="body1" color="textSecondary">
                  {T.translate('hyperlinks.preview.noData', {
                    defaultValue: 'No hyperlink candidates.',
                  })}
                </Typography>
              ) : (
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th className={classes.th}>
                        {T.translate('hyperlinks.preview.sourcePageHeader', {
                          defaultValue: 'Source Page',
                        })}
                      </th>
                      <th className={classes.th}>
                        {T.translate('hyperlinks.preview.sourceTextHeader', {
                          defaultValue: 'Source Text',
                        })}
                      </th>
                      <th className={classes.th}>
                        {T.translate('hyperlinks.preview.targetdocIdHeader', {
                          defaultValue: 'Target Doc Id.',
                        })}
                      </th>
                      <th className={classes.th}>
                        {T.translate('hyperlinks.preview.targetPageHeader', {
                          defaultValue: 'Target Page',
                        })}
                      </th>
                      <th className={classes.th}>
                        {T.translate('hyperlinks.preview.targetFilenameHeader', {
                          defaultValue: 'Target FileName',
                        })}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {hyperlinks.map((link, index) => (
                      <HyperlinkRow
                        key={index}
                        link={link}
                        classes={classes}
                        handleDeleteRow={handleDeleteRow}
                        index={index}
                        isEditing={isEditing}
                      />
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </Box>

          {hasConfirmed && !loading && hyperlinks.length > 0 && (
            <Box mt={2}>
              <Typography variant="body2">
                {T.translate('hyperlinks.preview.totalLinks', {
                  defaultValue: 'About to create {{totalLinks}} NEW hyperlinks.',
                  totalLinks,
                })}
              </Typography>

              {Object.keys(createdByBreakdown).length > 0 && (
                <Box mt={1}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {T.translate('hyperlinks.preview.breakdownTitle', {
                      defaultValue: 'Breakdown by user',
                    })}
                  </Typography>
                  {Object.entries(createdByBreakdown).map(([user, count]) => (
                    <Typography key={user} variant="body2">
                      {`${user}: ${count}`}
                    </Typography>
                  ))}
                </Box>
              )}

              {existingHyperlinks.length > 0 && (
                <Box mt={2}>
                  {showExisting && (
                    <Box mt={2}>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 'bold', mb: 1 }}
                        id="existingHyperlinksHeader"
                        className={classes.existingTitle}
                      >
                        {T.translate('hyperlinks.preview.existingTitle', {
                          defaultValue: 'Existing Hyperlinks',
                        })}
                      </Typography>
                      <div className={classes.tableContainer}>
                        <table className={classes.table}>
                          <thead>
                            <tr>
                              {isEditing && (
                                <th
                                  className={classes.th}
                                  style={{ width: '40px', textAlign: 'center' }}
                                >
                                  {T.translate('hyperlinks.preview.deleteColumnHeader', {
                                    defaultValue: '',
                                  })}
                                </th>
                              )}
                              <th className={classes.th}>
                                {T.translate('hyperlinks.preview.sourcePageHeader', {
                                  defaultValue: 'Source Page',
                                })}
                              </th>
                              <th className={classes.th}>
                                {T.translate('hyperlinks.preview.sourceTextHeader', {
                                  defaultValue: 'Source Text',
                                })}
                              </th>
                              <th className={classes.th}>
                                {T.translate('hyperlinks.preview.docIdHeader', {
                                  defaultValue: 'Source Doc Id.',
                                })}
                              </th>
                              <th className={classes.th}>
                                {T.translate('hyperlinks.preview.createdBy', {
                                  defaultValue: 'Created By',
                                })}
                              </th>
                              <th className={classes.th}>
                                {T.translate('hyperlinks.preview.linkPageHeader', {
                                  defaultValue: 'Source FileName',
                                })}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {existingHyperlinks.map((ann, idx) => (
                              <ExistingHyperlinkRow key={idx} ann={ann} />
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
      </LocalizationProvider>

      <DialogActions>
        {hasConfirmed && (
          <Box display="flex" alignItems="center" gap={1} mr="auto">
            <input
              type="checkbox"
              id="delete-hyperlinks"
              onChange={e => {
                setShowExisting(e.target.checked);
              }}
              checked={showExisting}
            />
            <label htmlFor="delete-hyperlinks" style={{ cursor: 'pointer', marginTop: 6 }}>
              {T.translate('hyperlinks.preview.deleteHyperlinks', {
                defaultValue: 'Delete {{ existing }} existing hyperlinks?',
                existing: existingHyperlinks.length,
              })}
            </label>
          </Box>
        )}

        <Button onClick={onClose} sx={{ color: '#748CEC' }}>
          {T.translate('hyperlinks.preview.cancel', {
            defaultValue: 'Cancel',
          })}
        </Button>

        <Button
          variant="contained"
          sx={{ backgroundColor: '#748CEC' }}
          onClick={handleCreateHyperlinks}
          disabled={!hasConfirmed || loading || hyperlinks.length === 0}
        >
          {T.translate('hyperlinks.preview.createButton', {
            defaultValue: 'Create',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConcealedPreviewHyperlinks;
