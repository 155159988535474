import * as React from 'react';

export interface UndoAction<T> {
  item: T;
  index: number;
}

export interface UndoableListReturn<T> {
  items: T[];
  setItems: React.Dispatch<React.SetStateAction<T[]>>;
  handleDeleteRow: (index: number) => void;
  handleUndoDelete: () => void;
  undoStack: UndoAction<T>[];
  clearUndoStack: () => void;
  undoMessage: string | null;
}

export function useUndoableList<T>(initialItems: T[]): UndoableListReturn<T> {
  const [items, setItems] = React.useState<T[]>(initialItems);
  const [undoStack, setUndoStack] = React.useState<UndoAction<T>[]>([]);
  const [undoMessage, setUndoMessage] = React.useState<string | null>(null);

  const handleDeleteRow = React.useCallback((index: number) => {
    setItems(prevItems => {
      const newItems = [...prevItems];
      const [removed] = newItems.splice(index, 1);
      setUndoStack(prevStack => [...prevStack, { item: removed, index }]);
      setUndoMessage('Row deleted. Press Ctrl+Z to undo.');
      return newItems;
    });
  }, []);

  const handleUndoDelete = React.useCallback(() => {
    setUndoStack(prevStack => {
      if (prevStack.length === 0) {
        return prevStack;
      }
      const lastAction = prevStack[prevStack.length - 1];
      const newStack = prevStack.slice(0, -1);

      setItems(prevItems => {
        const newItems = [...prevItems];
        newItems.splice(lastAction.index, 0, lastAction.item);
        return newItems;
      });

      return newStack;
    });
  }, []);

  const clearUndoStack = React.useCallback(() => {
    setUndoStack([]);
  }, []);

  React.useEffect(() => {
    if (!items || items.length === 0) return;

    const handleKeyDown = (evt: KeyboardEvent) => {
      const isUndoShortcut =
        evt.ctrlKey && !evt.shiftKey && !evt.altKey && evt.key.toLowerCase() === 'z';
      if (isUndoShortcut) {
        if (undoStack?.length > 0) {
          evt.preventDefault();
          handleUndoDelete();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [items, undoStack, handleUndoDelete]);

  return {
    items,
    setItems,
    handleDeleteRow,
    handleUndoDelete,
    undoStack,
    clearUndoStack,
    undoMessage,
  };
}
