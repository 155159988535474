import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import classnames from 'classnames';
import {
  faPlus,
  faMinus,
  faDownload,
  faRedo,
  faUndo,
  faFastBackward,
  faStepBackward,
  faFastForward,
  faStepForward,
  faPlusSquare,
  faLock,
  faShareSquare,
} from '@fortawesome/free-solid-svg-icons';
import { faChromecast } from '@fortawesome/free-brands-svg-icons';
import CustomViewerButton from 'features/common/CustomViewerButton';
import CustomViewerMultiActionButton from 'features/common/CustomViewerMultiActionButton';
import T from 'i18n';
import Tooltip from 'features/common/SimpleTooltip';
import { ZoomType } from '../../types';
import MouseToolSelectButtons from './MouseToolSelectButtons';
import InfoText from 'features/viewing/InfoText';
import { TooltipWrapper } from 'features/viewing/TooltipWrapper';
import PageFitmentButton from './PageFitmentButton';
import PausedBanner from './PausedBanner';
import CurrentGlobalPage from './CurrentGlobalPage';
import PageNumberControl from './PageNumberControl';
import { usePrevious } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { selectParams } from 'common/selectors';
import { useToasts } from 'react-toast-notifications';
import SimpleTooltip from 'features/common/SimpleTooltip';
import { useSetPageNumber } from 'features/viewing/redux/setPageNumber';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { DEFAULT_ANNOTATION_FILTER } from 'features/viewing/redux/filterAnnotations';
import { Avatar, Badge, ClickAwayListener, Popper } from '@mui/material';
import { documentModalActions } from 'features/case/enums';
import { useDispatch } from 'react-redux';
import { filterAnnotations } from 'features/viewing/redux/fetchAnnotations';
import FilterAnnotations from '../AnnotationSidebarList/FilterAnnotations';
import { FileVersions } from 'features/case/DocumentsModal/modals/FileVersions';

type AVToolboxProps = {
  viewerContainer: HTMLDivElement;
  pauseTheatreMode: boolean;
  isTheatreMode: boolean;
  presenter: string;
  fileId: string;
  presentMode: string | null;
  currentScaleFactor: number;
  pageCount: number;
  zoom: (type: string) => void;
  rotate: (angle: number) => void;
  unpauseTheatreMode: () => void;
  disconnectPauseTheatreMode: () => void;
  mouseTool: (toolType: any) => void;
  selectedMouseTool: string;
  goToFirstPage: () => void;
  goToLastPage: () => void;
  goToPrevPage: () => void;
  goToNextPage: () => void;
  setPageNumber: (pageNumber: number) => void;
  present: (mode?: 'private' | 'public', forcePresent?: boolean) => void;
  permissions: any;
  privateFile: boolean;
  showGlobalPaging: boolean;
  currentFileMetaData: any;
  isPresentModePage: boolean;
  isFullScreen: boolean;
  isPreviewMode?: boolean;
  userId: string;
};

export default forwardRef(
  (
    {
      viewerContainer,
      pauseTheatreMode,
      isTheatreMode,
      presenter,
      fileId,
      presentMode,
      currentScaleFactor,
      pageCount,
      zoom,
      rotate,
      unpauseTheatreMode,
      disconnectPauseTheatreMode,
      mouseTool,
      selectedMouseTool,
      goToFirstPage,
      goToLastPage,
      goToPrevPage,
      goToNextPage,
      setPageNumber,
      present,
      permissions,
      privateFile,
      showGlobalPaging,
      currentFileMetaData,
      isPresentModePage,
      isFullScreen,
      isPreviewMode,
      userId,
    }: AVToolboxProps,
    ref: any,
  ) => {
    const dispatch = useDispatch();

    const [showBottomButtons, setShowBottomButtons] = useState<boolean>(false);
    const [showLeftButtons, setShowLeftButtons] = useState<boolean>(false);
    const [annotationFilters, setAnnotationFilters] = useState<any>(DEFAULT_ANNOTATION_FILTER);
    const [filterPosition, setFilterPosition] = useState<HTMLElement | null>(null);
    const [showFileVariantsModal, setShowFileVariantsModal] = useState<boolean>(false);

    const showBottomButtonsTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const showLeftButtonsTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const {
      setCurrentPageNumber,
      setGoToPageNumber,
      currentPageNumber,
      goToPageNumber,
    } = useSetPageNumber();

    const currentScaleFactorPrev = usePrevious(currentScaleFactor);
    const currentPageNumberPrev = usePrevious(currentPageNumber);

    const { addToast } = useToasts();

    const { case: aCase, file } = useSelector(selectParams) as any;

    const filterCount =
      Object.keys(annotationFilters).filter(key => !annotationFilters[key]).length +
      (annotationFilters.createdBy
        ? Object.keys(annotationFilters.createdBy).filter(key => !annotationFilters.createdBy[key])
            .length
        : 0);

    const showBottomButtonsHandler = useCallback(() => {
      !showBottomButtons && setShowBottomButtons(true);
      if (showBottomButtonsTimeoutRef.current) {
        clearTimeout(showBottomButtonsTimeoutRef.current);
        showBottomButtonsTimeoutRef.current = null;
      }
    }, [showBottomButtons]);

    const hideBottomButtonsHandler = () => {
      if (showBottomButtons && !showBottomButtonsTimeoutRef.current)
        showBottomButtonsTimeoutRef.current = setTimeout(() => setShowBottomButtons(false), 3000);
    };

    const showLeftButtonsHandler = useCallback(() => {
      !showLeftButtons && setShowLeftButtons(true);
      if (showLeftButtonsTimeoutRef.current) {
        clearTimeout(showLeftButtonsTimeoutRef.current);
        showLeftButtonsTimeoutRef.current = null;
      }
    }, [showLeftButtons]);

    const hideLeftButtonsHandler = () => {
      if (showLeftButtons && !showLeftButtonsTimeoutRef.current)
        showLeftButtonsTimeoutRef.current = setTimeout(() => setShowLeftButtons(false), 3000);
    };

    useEffect(() => {
      viewerContainer.addEventListener('mousemove', e => {
        const { left, top } = viewerContainer.getBoundingClientRect();
        if (
          e.clientY - top >
          Math.min(viewerContainer.clientHeight * 0.9, viewerContainer.clientHeight - 60)
        )
          showBottomButtonsHandler();
        else hideBottomButtonsHandler();

        if (e.clientX - left < Math.max(viewerContainer.clientWidth * 0.15, 80))
          showLeftButtonsHandler();
        else hideLeftButtonsHandler();
      });

      return () => {
        showBottomButtonsTimeoutRef.current && clearTimeout(showBottomButtonsTimeoutRef.current);
        showLeftButtonsTimeoutRef.current && clearTimeout(showLeftButtonsTimeoutRef.current);
      };
      // MOUNT
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (currentScaleFactorPrev !== currentScaleFactor) showLeftButtonsHandler();
      if (currentPageNumberPrev !== currentPageNumber) showBottomButtonsHandler();
    }, [
      currentPageNumber,
      currentPageNumberPrev,
      currentScaleFactor,
      currentScaleFactorPrev,
      showBottomButtonsHandler,
      showLeftButtonsHandler,
    ]);

    const copyUrlHandler = () => {
      const url = `${window.location.origin}/case/${aCase}/files/${file}?goToPage=${currentPageNumber}`;

      const success = (
        <>
          <strong>{T.translate('case.fileId', { file })}</strong>
          <div>{T.translate('case.urlCoppied', { url })}</div>
        </>
      );

      const failure = (
        <>
          <strong>{T.translate('case.fileId', { file })}</strong>
          <div>{T.translate('case.urlCoppyUnsuccessfull', { url })}</div>
        </>
      );

      navigator.clipboard.writeText(url).then(
        function() {
          addToast(success, {
            appearance: 'info',
            autoDismiss: true,
          });
        },
        function(err) {
          addToast(failure, {
            appearance: 'error',
            autoDismiss: false,
          });
        },
      );
    };

    const filterClicked = (event: any) => {
      setFilterPosition(event.currentTarget);
    };

    const handleClickAway = () => {
      setFilterPosition(null);
    };

    const filterHandler = (val: any) => {
      setAnnotationFilters(val);
      dispatch(filterAnnotations(val, userId));
    };

    const isPresenting = !!presentMode;

    useImperativeHandle(ref, () => ({
      pageNumberChangedHandler: ({ pageNumber }: any) => {
        if (currentPageNumber !== pageNumber) setCurrentPageNumber(pageNumber);
        if (goToPageNumber === pageNumber) setGoToPageNumber(null);
      },
    }));

    // TO-DO this needs to go, the whole goToPageNumber thing is a mess
    useEffect(() => {
      if (!isTheatreMode && fileId === file) {
        // we need fileId === file because if for present-mode we switch file it breaks
        // so if we are changing file then skip this step, if keeping the same file then do it
        if (goToPageNumber && goToPageNumber !== currentPageNumber) {
          // setPageNumber(goToPageNumber);
          setTimeout(() => setPageNumber(goToPageNumber), 1000); // wait for a second to get stuff ready, isViwerReady did not help
        }
      }
    }, [
      currentPageNumber,
      goToPageNumber,
      isTheatreMode,
      setPageNumber,
      setGoToPageNumber,
      fileId,
      file,
    ]);

    // console.log('AcusoftViewerToolbox', 'render');

    return (
      <div className="viewing-custom-viewer-toolbox">
        {isTheatreMode && pauseTheatreMode && (
          <PausedBanner
            unpauseTheatreMode={unpauseTheatreMode}
            disconnectPauseTheatreMode={disconnectPauseTheatreMode}
          />
        )}
        {!isPreviewMode && (
          <>
            <div className="document-options top-left">
              <MouseToolSelectButtons
                mouseTool={mouseTool}
                selectedMouseTool={selectedMouseTool}
                currentPageNumber={currentPageNumber}
              />
            </div>
            <div className="document-options top-right">
              {isTheatreMode ? (
                <InfoText presenter={presenter} fileId={fileId} />
              ) : isPresenting ? (
                <TooltipWrapper isPresenting={isPresenting}>
                  <CustomViewerButton
                    icon={faChromecast}
                    icon2={presentMode === 'private' && faLock}
                    className={classnames({ present: isPresenting })}
                    onClick={() => {
                      present();
                    }}
                  />
                </TooltipWrapper>
              ) : (
                (permissions.hearingRoom.canPresent('private') ||
                  permissions.hearingRoom.canPresent('public')) && (
                  <>
                    {isPresentModePage ? (
                      <Tooltip title={T.translate('case.presentToPublicRoomTooltip')}>
                        <CustomViewerButton
                          icon={faChromecast}
                          onClick={() => present('public')}
                          disabled={!permissions.hearingRoom.canPresent('public') || privateFile}
                        />
                      </Tooltip>
                    ) : (
                      <>
                        {isTheatreMode ||
                          (!isFullScreen && (
                            <ClickAwayListener onClickAway={handleClickAway}>
                              <div>
                                <CustomViewerButton icon={faChromecast} onClick={filterClicked}>
                                  <Badge
                                    badgeContent={filterCount}
                                    overlap="circular"
                                    color="primary"
                                  >
                                    <Avatar className="filterAnnotationsButton">
                                      <FilterListRoundedIcon style={{ color: 'gray' }} />
                                    </Avatar>
                                  </Badge>
                                </CustomViewerButton>
                                <Popper
                                  id={'simple-popover'}
                                  className="popper-shadow"
                                  open={!!filterPosition}
                                  anchorEl={filterPosition}
                                  disablePortal={true}
                                  placement="left-start"
                                  style={{ pointerEvents: 'all', width: '18rem' }}
                                >
                                  <FilterAnnotations filterHandler={filterHandler} />
                                </Popper>
                              </div>
                            </ClickAwayListener>
                          ))}
                        <CustomViewerMultiActionButton direction="down">
                          <CustomViewerButton icon={faChromecast} onClick={() => {}} />
                          <Tooltip title={T.translate('case.presentToPrivateRoomTooltip')}>
                            <CustomViewerButton
                              icon={faChromecast}
                              icon2={faLock}
                              onClick={() => {
                                present('private');
                              }}
                              disabled={!permissions.hearingRoom.canPresent('private')}
                            />
                          </Tooltip>
                          <Tooltip title={T.translate('case.presentToPublicRoomTooltip')}>
                            <CustomViewerButton
                              icon={faChromecast}
                              onClick={() => present('public')}
                              disabled={
                                !permissions.hearingRoom.canPresent('public') || privateFile
                              }
                            />
                          </Tooltip>
                        </CustomViewerMultiActionButton>
                      </>
                    )}
                  </>
                )
              )}
            </div>
            <div className={classnames({ show: showLeftButtons }, 'document-options left')}>
              <CustomViewerButton icon={faPlus} onClick={() => zoom(ZoomType.In)} />
              <CustomViewerButton icon={faMinus} onClick={() => zoom(ZoomType.Out)} />
              <PageFitmentButton
                zoomFitPage={() => zoom(ZoomType.Page)}
                zoomFitWidth={() => zoom(ZoomType.Width)}
              />
            </div>
            <div className="document-options right" style={{ flexDirection: 'row' }}>
              {showGlobalPaging &&
                currentFileMetaData &&
                Object.keys(currentFileMetaData).length > 0 && (
                  <CurrentGlobalPage
                    currentFileMetaData={currentFileMetaData}
                    currentPageNumber={currentPageNumber}
                    setPageNumber={setPageNumber}
                    fileId={fileId}
                  />
                )}
              <CustomViewerMultiActionButton>
                <CustomViewerButton icon={faRedo} onClick={() => rotate(90)} />
                <CustomViewerButton icon={faUndo} onClick={() => rotate(-90)} />
                {permissions.folders.canDownloadDocuments && (
                  <CustomViewerButton
                    icon={faDownload}
                    onClick={() => setShowFileVariantsModal(true)}
                  />
                )}
                <SimpleTooltip title={T.translate('case.copyUrl')}>
                  <CustomViewerButton icon={faShareSquare} onClick={copyUrlHandler} />
                </SimpleTooltip>
                <CustomViewerButton icon={faPlusSquare} onClick={() => {}} />
              </CustomViewerMultiActionButton>
            </div>
          </>
        )}
        <div className={classnames({ show: showBottomButtons }, 'document-options toolbox')}>
          <CustomViewerButton icon={faFastBackward} onClick={goToFirstPage} />
          <CustomViewerButton icon={faStepBackward} onClick={goToPrevPage} />
          <PageNumberControl
            key={currentPageNumber}
            currentPageNumber={currentPageNumber}
            setPageNumber={setPageNumber}
            pageCount={pageCount}
          />
          <CustomViewerButton icon={faStepForward} onClick={goToNextPage} />
          <CustomViewerButton icon={faFastForward} onClick={goToLastPage} />
        </div>
        {showFileVariantsModal && (
          <FileVersions
            show={showFileVariantsModal}
            selectedRows={[currentFileMetaData ? currentFileMetaData : {}]}
            handleClose={() => setShowFileVariantsModal(false)}
            action={documentModalActions.download}
          />
        )}
      </div>
    );
  },
);
