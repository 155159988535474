import { selectCurrentSelectedFileMetaData, selectSpecificParam } from 'common/selectors';
import { useFetchFolderSpecificFileDetails } from 'features/case/redux/fetchFolderSpecificFileDetails';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default (
  isTrialBundle: boolean,
  globalPaging: boolean,
  isGlobalPageLookUp: boolean,
  isPresentModePage: boolean,
  isTagsFolder: boolean,
  isSearchModeRefreshed: boolean,
) => {
  //@ts-ignore
  const file = useSelector(state => selectSpecificParam(state, 'file'));
  const fileMetaData = useSelector(selectCurrentSelectedFileMetaData) as any;

  const { fetchFolderSpecificFileDetails } = useFetchFolderSpecificFileDetails();

  const [currentFileMetaData, setCurrentFileMetaData] = useState({} as any);
  const [bundleSpecificFileDetails, setBundleSpecificFileDetails] = useState({} as any);
  const [isFetchingDetails, setIsFetchingDetails] = useState(false);

  //If SEARCH/team/private bundle show GP inside viewer from 1st trialbundle of bundle location
  useEffect(() => {
    if (
      (isTrialBundle && !globalPaging) ||
      (!isTrialBundle && !isGlobalPageLookUp && !isPresentModePage)
    ) {
      const fileId = isSearchModeRefreshed ? file : fileMetaData.id;

      if (!fileId) {
        setBundleSpecificFileDetails({});
        setCurrentFileMetaData(fileMetaData);
        return;
      }
      if (isFetchingDetails) return;
      if (currentFileMetaData && currentFileMetaData?.id === fileId) return;
      setIsFetchingDetails(true);
      fetchFolderSpecificFileDetails({ fileId })
        .then((res: any) => {
          const bundleDetails = res[0];
          if (bundleDetails) {
            setCurrentFileMetaData({
              ...fileMetaData,
              ...bundleDetails,
            });
            setBundleSpecificFileDetails(bundleDetails);
          } else if (isTagsFolder || isSearchModeRefreshed) {
            const gpDetails = res.find((itm: any) => itm.containerType === 'trialbook') || {};
            if (gpDetails) {
              setCurrentFileMetaData({
                ...fileMetaData,
                ...gpDetails,
              });
              setBundleSpecificFileDetails(gpDetails);
            }
          } else {
            setBundleSpecificFileDetails({});
            setCurrentFileMetaData(fileMetaData);
          }
          setIsFetchingDetails(false);
        })
        .finally(() => setIsFetchingDetails(false));
    } else {
      setBundleSpecificFileDetails({});
      setCurrentFileMetaData(fileMetaData);
    }
  }, [
    isFetchingDetails,
    currentFileMetaData,
    fileMetaData,
    fetchFolderSpecificFileDetails,
    file,
    globalPaging,
    isGlobalPageLookUp,
    isPresentModePage,
    isSearchModeRefreshed,
    isTagsFolder,
    isTrialBundle,
  ]);

  return { currentFileMetaData, bundleSpecificFileDetails } as any;
};
