import React from 'react';
import DraggableRow from './DraggableRow';

type ListRowProps = {
  data?: any;
  index: number;
  style?: React.CSSProperties;
  isScrolling?: boolean;
};

export default React.memo(
  ({
    data: {
      data: allRows,
      onDoubleClick,
      columns,
      sortMode,
      editMode,
      additionalDragInfo,
      onRowSelected,
      selectedRowIds = [],
      setSelectedRowsIds,
      shiftPressedIndex,
      setShiftPressedIndex,
      hoverable,
      readOnly,
      forwardedRef,
      focusNextRow,
      handleSaveChanges,
      handleCancelChanges,
      onRowClick,
      currentlyEditingRowIndex,
      firstEditableCellRefs,
      mappedRowsWithErrors,
    } = {},
    index,
    style,
    isScrolling,
  }: ListRowProps) => {
    const row = allRows[index] ? allRows[index] : { compositeKey: '', id: '' };
    const isSelectedRow = selectedRowIds.includes(row.compositeKey || row.id);
    const firstEditableCellRef = firstEditableCellRefs ? firstEditableCellRefs[index] : null;

    return (
      <DraggableRow
        ref={forwardedRef}
        index={index}
        style={style}
        row={row}
        onDoubleClick={onDoubleClick}
        columns={columns}
        sortMode={sortMode}
        editMode={editMode}
        additionalDragInfo={additionalDragInfo}
        onRowSelected={onRowSelected}
        setSelectedRowsIds={setSelectedRowsIds}
        selected={isSelectedRow}
        selectedRowIds={selectedRowIds}
        shiftPressedIndex={shiftPressedIndex}
        setShiftPressedIndex={setShiftPressedIndex}
        hoverable={hoverable}
        readOnly={readOnly}
        allRows={allRows}
        focusNextRow={focusNextRow}
        handleSaveChanges={handleSaveChanges}
        handleCancelChanges={handleCancelChanges}
        onRowClick={onRowClick}
        currentlyEditingRowIndex={currentlyEditingRowIndex}
        firstEditableCellRef={firstEditableCellRef}
        isScrolling={isScrolling}
        mappedRowsWithErrors={mappedRowsWithErrors}
      />
    );
  },
);
