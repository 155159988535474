/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  IconButton,
  Checkbox,
  Box,
} from '@mui/material';
import T from 'i18n';
import { makeStyles } from '@mui/styles';
import { Edit, EditOff } from '@mui/icons-material';
import { validateDateFieldNoPush } from './utils';
import { fileIdentifiers } from './constants';

type MappingPreviewProps = {
  open: boolean;
  onClose: () => void;
  mappingData: any[];
  fileIdentifier?: string;
  setMappingData: (data: any[]) => void;
  setFailedRows: (rows: any[]) => void;
  failedRows: any[];
  formatsForDates?: string[];
  requiredFields?: string[];
  onSubmitMapping?: (finalData?: any[]) => void;
  fieldsWithAppend?: string[];
};

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    borderBottom: '1px solid #ccc',
    textAlign: 'left',
    padding: '8px',
    position: 'sticky',
    top: 0,
    backgroundColor: '#f9f9f9',
    zIndex: 1,
  },
  td: {
    borderBottom: '1px solid #eee',
    padding: '8px',
  },
});

const MappingPreview: React.FC<MappingPreviewProps> = ({
  open,
  onClose,
  mappingData,
  fileIdentifier,
  setMappingData,
  setFailedRows,
  failedRows,
  formatsForDates,
  requiredFields,
  onSubmitMapping,
  fieldsWithAppend,
}) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);

  const masterDataRef = useRef<any[]>([]);

  useEffect(() => {
    if (mappingData.length > 0 && masterDataRef.current.length === 0) {
      masterDataRef.current = JSON.parse(JSON.stringify(mappingData));
    }
  }, [mappingData]);
  useEffect(() => {
    if (!open) {
      masterDataRef.current = [];
    }
  }, [open]);

  const [excludedColumns, setExcludedColumns] = useState<string[]>([]);

  const allColumns: Set<string> = useMemo(() => {
    const columns = new Set<string>();

    if (mappingData[0]) {
      Object.keys(mappingData[0]).forEach(k => columns.add(k));
    }
    if (masterDataRef.current[0]) {
      Object.keys(masterDataRef.current[0]).forEach(k => columns.add(k));
    }

    columns.delete('uniqueId');
    return columns;
  }, [mappingData]);

  let displayedColumns = [...allColumns];

  if (displayedColumns.includes('rowNumber')) {
    displayedColumns = ['rowNumber', ...displayedColumns.filter(c => c !== 'rowNumber')];
  }
  if (displayedColumns.includes('__primaryKey')) {
    displayedColumns = [
      'rowNumber',
      '__primaryKey',
      ...displayedColumns.filter(c => !['rowNumber', '__primaryKey'].includes(c)),
    ];
  }

  const failedFieldsMap = useMemo(() => {
    const map: { [rowNumber: number]: { [col: string]: any[] } } = {};
    failedRows.forEach(issue => {
      const rn = issue?.rowNumber;
      const cn = issue?.columnName;
      if (rn != null && cn) {
        map[rn] = map[rn] || {};
        map[rn][cn] = map[rn][cn] || [];
        map[rn][cn].push(issue);
      }
    });
    return map;
  }, [failedRows]);

  const toggleColumnExcluded = (col: string) => {
    setExcludedColumns(prev => {
      const isExcluded = prev.includes(col);
      let newExcluded = [...prev];

      if (isExcluded) {
        newExcluded = newExcluded.filter(c => c !== col);

        const newMappingData = mappingData.map((row, idx) => {
          const masterRow = masterDataRef.current[idx] || {};
          const newRow = { ...row, [col]: masterRow[col] };
          return newRow;
        });
        setMappingData(newMappingData);
      } else {
        newExcluded.push(col);
        const newMappingData = mappingData.map(row => {
          const copy = { ...row };
          delete copy[col];
          return copy;
        });
        setMappingData(newMappingData);
      }
      return newExcluded;
    });
  };

  const handleCellChange = (rowIndex: number, col: string, newValue: string) => {
    if (['rowNumber', '__primaryKey'].includes(col)) return;
    if (excludedColumns.includes(col)) return;

    const newMD = [...mappingData];
    newMD[rowIndex] = { ...newMD[rowIndex], [col]: newValue };
    setMappingData(newMD);

    masterDataRef.current[rowIndex][col] = newValue;

    const rowNumber = newMD[rowIndex]?.rowNumber;
    let updatedFailedRows = [...failedRows];
    const existingIssues =
      rowNumber != null && failedFieldsMap[rowNumber]?.[col] ? failedFieldsMap[rowNumber][col] : [];
    if (existingIssues.length > 0) {
      updatedFailedRows = updatedFailedRows.filter(i => !existingIssues.includes(i));
    }

    const cellValue = newValue.trim();
    const isRequired = requiredFields?.includes(col);

    let isInvalidDate = false;
    if (col.toLowerCase().includes('date') && formatsForDates?.length) {
      isInvalidDate = validateDateFieldNoPush(col, cellValue, formatsForDates);
    }

    if (isRequired && !cellValue) {
      updatedFailedRows.push({
        rowNumber,
        reason: T.translate('case.batchUpload.dataMapper.reasons.missingRequiredField', {
          row: rowNumber,
          columnName: col,
        }),
        type: 'warning',
        problemCategory: 'missingRequiredField',
        columnName: col,
      });
    }
    if (isInvalidDate) {
      updatedFailedRows.push({
        rowNumber,
        reason: T.translate('case.batchUpload.dataMapper.reasons.invalidDate', {
          row: rowNumber,
          columnName: col,
        }),
        type: 'warning',
        problemCategory: 'invalidDate',
        columnName: col,
      });
    }
    setFailedRows(updatedFailedRows);
  };

  /**
   * handleMapClick => finalize. mappingData is already missing any columns that are excluded.
   */
  const handleMapClick = () => {
    if (!onSubmitMapping) return;
    onClose();
    onSubmitMapping(mappingData);
  };
  const isMapButtonDisabled = useMemo(() => {
    const userColumns = displayedColumns.filter(
      col => !excludedColumns.includes(col) && !['rowNumber', '__primaryKey'].includes(col),
    );

    return userColumns.length === 0;
  }, [displayedColumns, excludedColumns]);
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        {T.translate('case.batchUpload.dataMapper.preview.previewTitle')}
        <IconButton onClick={() => setIsEditing(!isEditing)}>
          {isEditing ? <EditOff sx={{ color: '#ECD474' }} /> : <Edit />}
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.tableContainer}>
        {mappingData.length === 0 ? (
          <Typography variant="body1" color="textSecondary">
            {T.translate('case.batchUpload.dataMapper.preview.noPreviewData')}
          </Typography>
        ) : (
          <table className={classes.table}>
            <thead>
              <tr>
                {displayedColumns.map(col => {
                  const isReadOnly = col === 'rowNumber' || col === '__primaryKey';
                  const isExcluded = excludedColumns.includes(col);

                  return (
                    <th key={col} className={classes.th}>
                      <Box display="flex" alignItems="center">
                        {isReadOnly ? (
                          <strong>
                            {col === '__primaryKey'
                              ? T.translate(
                                  'case.batchUpload.dataMapper.preview.fileIdentifierWarning',
                                )
                              : col === 'rowNumber'
                              ? 'Row #'
                              : col}
                          </strong>
                        ) : (
                          <>
                            <Typography variant="body2" sx={{ ml: isEditing ? 0.5 : 0 }}>
                              {fieldsWithAppend?.includes(col)
                                ? `${T.translate(fileIdentifiers?.get(col) as string)} - (append)`
                                : T.translate(fileIdentifiers?.get(col) as string)}
                            </Typography>
                            {isEditing && (
                              <Checkbox
                                size="small"
                                checked={!isExcluded}
                                onChange={() => toggleColumnExcluded(col)}
                              />
                            )}
                          </>
                        )}
                      </Box>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {mappingData.map((row, rowIndex) => {
                const rowNum = row?.rowNumber;

                return (
                  <tr key={rowIndex}>
                    {displayedColumns.map(col => {
                      const isExcluded = excludedColumns.includes(col);
                      const cellIssues =
                        rowNum != null && failedFieldsMap[rowNum]?.[col]
                          ? failedFieldsMap[rowNum][col]
                          : [];

                      let cellStyle: React.CSSProperties = {};
                      if (isExcluded) {
                        cellStyle.backgroundColor = '#d4d4d4';
                      } else if (cellIssues.some(i => i.problemCategory === 'invalidDate')) {
                        cellStyle.backgroundColor = '#ECD474';
                      } else if (
                        cellIssues.some(i => i.problemCategory === 'missingRequiredField')
                      ) {
                        cellStyle.backgroundColor = '#ECD474';
                      }

                      const isReadOnly =
                        col === 'rowNumber' || col === '__primaryKey' || isExcluded;

                      let cellValue = row[col];
                      if (isExcluded) {
                        const masterRow = masterDataRef.current[rowIndex] || {};
                        if (masterRow[col] !== undefined && masterRow[col] !== null) {
                          cellValue = masterRow[col];
                        } else {
                          cellValue = '(excluded)';
                        }
                      }
                      if (cellValue == null) cellValue = '';

                      return (
                        <td key={col} className={classes.td} style={cellStyle}>
                          {isEditing && !isReadOnly ? (
                            <TextField
                              variant="standard"
                              InputProps={{ disableUnderline: true }}
                              value={String(cellValue)}
                              onChange={e => handleCellChange(rowIndex, col, e.target.value)}
                            />
                          ) : (
                            String(cellValue)
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </DialogContent>
      <DialogActions>
        <Typography variant="body2" color="textSecondary" sx={{ my: 2 }}>
          {T.translate('case.batchUpload.dataMapper.preview.mappingWarning')}
        </Typography>

        <Button onClick={onClose} sx={{ color: '#748CEC' }}>
          {T.translate('case.batchUpload.dataMapper.preview.closePreview')}
        </Button>
        {onSubmitMapping && (
          <Button
            variant="contained"
            sx={{ backgroundColor: '#748CEC' }}
            onClick={handleMapClick}
            disabled={isMapButtonDisabled}
          >
            {T.translate('case.batchUpload.dataMapper.preview.mapButton')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MappingPreview;
