import React from 'react';
import T from 'i18n';
import { MenuItem, Select } from '@mui/material';
import { isMobile } from 'utils/device';

const SubjectLabel = ({ data, setValue, setSelectedVal, value }) => {
  const handleChange = e => {
    setValue('subject', e.target.value);
    setSelectedVal(e.target.value);
  };

  return (
    <div>
      <span>{T.translate('case.suggestedSubject')} </span>
      <Select
        value={value}
        onChange={handleChange}
        variant="standard"
        autoWidth
        size="small"
        sx={{
          '&::before': { borderBottom: 'none' },
          '&::after': { borderBottom: 'none' },
          maxWidth: '150px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxWidth: isMobile ? '70vw' : '30vw',
              wordWrap: 'break-word',
            },
          },
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem
          value={data}
          sx={{
            maxWidth: isMobile ? '70vw' : '30vw',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
          }}
        >
          {data}
        </MenuItem>
      </Select>
    </div>
  );
};

export default SubjectLabel;
