import React, { useEffect, useState } from 'react';
import T from 'i18n';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as actions from '../../redux/actions';
import { Spinner, ToggleForm } from 'features/common';
import { Col, Form, Row } from 'react-bootstrap';
import { Modal } from 'features/common/MaterialBasedComponents';

export const DownloadModal = ({ show, handleClose, selectedRows, folder, containers }) => {
  const fileVersionOptions = [
    { name: 'case.downloadFolderModal.fileVersionOptions.originalFile', version: 'original' },
    {
      name: 'case.downloadFolderModal.fileVersionOptions.originalAsPDF',
      version: 'pdf',
    },
    // {
    //   name: 'case.downloadFolderModal.fileVersionOptions.originalAsPDFOCR',
    //   version: 'originalAsPDFOCR',
    // },
    {
      name: 'case.downloadFolderModal.fileVersionOptions.mostRecentPDF',
      version: 'recent-pdf',
    },
    {
      name: 'case.downloadFolderModal.fileVersionOptions.mostRecentOCR',
      version: 'recent-ocr',
    },
  ];

  const fileNameOptions = [
    {
      name: 'case.downloadFolderModal.fileNameOptions.originalFileName',
      fileName: 'original',
    },
    { name: 'case.downloadFolderModal.fileNameOptions.fileName', fileName: 'folderSpecificName' },
    { name: 'case.downloadFolderModal.fileNameOptions.trialviewID', fileName: 'trialviewId' },
    { name: 'case.downloadFolderModal.fileNameOptions.externalId', fileName: 'externalId' },
    { name: 'case.downloadFolderModal.fileNameOptions.discoveryId', fileName: 'discoveryId' },
  ];
  // TODO: Temporary for testing while we prepare backend
  const disableOptions = false;
  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      source: fileVersionOptions[0].version,
      filenameSelector: fileNameOptions[0].fileName,
      includeMerge: false,
    },
  });
  const dispatch = useDispatch();

  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [fileVersion, setFileVersion] = useState(fileVersionOptions[0]);
  const [fileName, setFileName] = useState(fileNameOptions[0]);
  const [includeMergeFiles, setIncludeMergeFiles] = useState(false);

  useEffect(() => {
    if (show) {
      reset();
    }
  }, [selectedRows, folder, show, reset]);

  const handleFileVersionChange = e => {
    setFileVersion(e.target.value);
    setValue('source', e.target.value);
  };

  const handleFileNameChange = e => {
    setFileName(e.target.value);
    setValue('filenameSelector', e.target.value);
  };

  const handleDownload = data => {
    setDownloadInProgress(true);
    dispatch(
      actions.downloadFolder({
        folderId: folder?.id,
        ...data,
      }),
    )
      .then(response => {
        const link = document.createElement('a');
        link.href = response;
        link.download = `${folder?.name}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .then(() => {
        setDownloadInProgress(false);
        handleClose();
      });
  };

  return (
    <Modal
      show={show}
      title={T.translate('case.downloadFolderModal.title')}
      handleClose={handleClose}
      handleSave={handleSubmit(handleDownload)}
      saveTitle={
        downloadInProgress ? (
          <Spinner
            style={{
              height: '1.5rem',
            }}
          />
        ) : (
          T.translate('generic.download')
        )
      }
      disableSave={downloadInProgress}
    >
      <Form.Group
        style={{
          marginBottom: '3rem',
        }}
      >
        <div
          style={{
            marginBottom: '1rem',
          }}
        >
          {T.translate('case.downloadFolderModal.downloadMsg', { folderName: folder?.name })}
        </div>

        {/* File Version Dropdown */}
        <Row>
          <Col>
            <Form.Label>{T.translate('common.fileVersion')}</Form.Label>
            <Form.Control
              {...register('source')}
              as="select"
              value={fileVersion}
              onChange={handleFileVersionChange}
              readOnly={downloadInProgress}
              disabled={disableOptions}
            >
              {fileVersionOptions.map(value => (
                <option key={value.version} value={value.version}>
                  {T.translate(value.name)}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>

        {/* File Name Dropdown */}
        <Row>
          <Col>
            <Form.Label>{T.translate('common.fileName')}</Form.Label>
            <Form.Control
              {...register('filenameSelector')}
              disabled={disableOptions}
              as="select"
              value={fileName}
              onChange={handleFileNameChange}
              readOnly={downloadInProgress}
            >
              {fileNameOptions.map(value => (
                <option key={value.name} value={value.fileName}>
                  {T.translate(value.name)}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        {/* Include Merge Files Toggle */}
        <Row>
          <Col>
            <ToggleForm
              {...register('includeMerge')}
              defaultValue={includeMergeFiles}
              label={T.translate('case.downloadFolderModal.includeMergeFiles')}
              onChange={newValue => {
                setIncludeMergeFiles(newValue);
                setValue('includeMerge', newValue.target.checked);
              }}
              readOnly={downloadInProgress}
              disabled={disableOptions}
            />
          </Col>
        </Row>
      </Form.Group>
    </Modal>
  );
};

export default DownloadModal;
