import React, { useEffect, useState } from 'react';
import T from 'i18n';
import {
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Menu,
  useMediaQuery,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { selectEnableNotifications } from 'common/selectors';
import { useFetchNotifications } from 'features/case/redux/fetchNotifications';
import { NotificationsOutlined } from '@mui/icons-material';
import { useUpdateNotifications } from 'features/case/redux/updateNotifications';
import NotificationItem from './NotificationItem';
import { usePrevious } from 'utils/hooks';

const maxBadgeVal = 99;

const useStyles = makeStyles(() => ({
  notificationIcon: {
    padding: '0.5rem',
    '&:hover': {
      color: 'rgba(0,0,0,.7)',
    },
  },
  notificationMenu: {
    '& .MuiMenu-paper': {
      border: '1px solid rgba(0,0,0,.15)',
      borderRadius: '.25rem',
      padding: 0,
      boxShadow: 'none',
      overflow: 'visible',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 10,
        width: 10,
        height: 10,
        backgroundColor: 'white',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
        borderLeft: '1px solid rgba(0,0,0,.15)',
        borderTop: '1px solid rgba(0,0,0,.15)',
      },
    },
  },
  notificationSubheader: {
    lineHeight: '2.5rem',
    fontSize: '1rem',
    fontWeight: '600',
    padding: 0,
  },
  notificationSubheaderHeading: {
    paddingLeft: '1.3rem',
    paddingRight: '0.3rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  clearAllBtn: {
    color: '#7ba0f4',
    '&:hover': {
      color: '#0619f5',
    },
  },
  notificationItemBgd: {
    backgroundColor: 'rgba(108, 103, 129, 0.1)',
  },
}));

const Notifications = ({ mainTheme }) => {
  const classes = useStyles();
  const { fetchNotifications, notifications } = useFetchNotifications();
  const { updateNotifications } = useUpdateNotifications();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isNotificationsEnabled = useSelector(selectEnableNotifications);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const newNotificationsCount =
    (notifications && notifications.filter(itm => !itm.read).length) || 0;

  const clearAllHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    updateNotifications({ data: { hide: true } });
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    fetchNotifications();
  };

  const handleClose = e => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isNotificationsEnabled) {
      fetchNotifications();
    }
  }, [isNotificationsEnabled, fetchNotifications]);

  return (
    isNotificationsEnabled && (
      <>
        <div style={{ alignSelf: 'center' }}>
          <IconButton
            id="notifications-icon-btn"
            size="large"
            disableRipple
            onClick={handleClick}
            className={classes.notificationIcon}
            sx={{
              ...((mainTheme === 'dark' || mainTheme === 'blue') && { color: 'white' }),
            }}
          >
            <Badge badgeContent={newNotificationsCount} color="primary" max={maxBadgeVal}>
              <NotificationsOutlined />
            </Badge>
          </IconButton>
        </div>
        <Menu
          anchorEl={anchorEl}
          id="notifications-menu"
          open={open}
          onClose={handleClose}
          className={classes.notificationMenu}
          PaperProps={{
            sx: {
              width: isMobile ? '70vw' : '25vw',
            },
          }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <Box>
            <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: '50vh' }}>
              <List
                dense
                subheader={
                  <ListSubheader className={classes.notificationSubheader}>
                    <span className={classes.notificationSubheaderHeading}>
                      {T.translate('case.notifications')}
                      {notifications && notifications.length > 0 && (
                        <Button
                          variant="text"
                          className={classes.clearAllBtn}
                          onClick={e => clearAllHandler(e)}
                        >
                          {T.translate('generic.clearAll')}
                        </Button>
                      )}
                    </span>
                    <Divider />
                  </ListSubheader>
                }
              >
                {notifications && notifications.length > 0 ? (
                  notifications.map((item, index) => (
                    <React.Fragment key={item.id || index}>
                      <NotificationItem notificationItem={item} handleClose={handleClose} />
                      {index !== notifications.length - 1 && <Divider />}
                    </React.Fragment>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText>{T.translate('common.noNotificationsMsg')}</ListItemText>
                  </ListItem>
                )}
              </List>
            </PerfectScrollbar>
          </Box>
        </Menu>
      </>
    )
  );
};

export default Notifications;
