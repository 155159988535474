import React, { useCallback, useEffect, useState } from 'react';
import T from 'i18n';
import Button from '@mui/material/Button';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  selectAutoSetDoDate,
  selectChangeDocId,
  selectCurrentFolder,
  selectCurrentFolderAgreed,
  selectParams,
  selectPermissions,
} from 'common/selectors';
import { usePrevious } from 'utils/hooks';
import { Controller, useForm } from 'react-hook-form';
import history from 'common/history';
import * as actions from '../../redux/actions';
import isEqual from 'react-fast-compare';
import moment from 'moment';
import { MultiSelectForm, Spinner, TextForm } from 'features/common';
import { Collapse, Divider, IconButton, InputAdornment, TextField } from '@mui/material';
import Split from 'react-split';
import { suggestedRecipientsMenuOptions } from '../../enums';
import classnames from 'classnames';
import { Col, Form, Row } from 'react-bootstrap';
import SubjectLabel from '../labels/SubjectLabel';
import DateFormLabel from '../labels/DateFormLabel';
import DateInput from 'features/common/FormControls/DateInput';
import TimeInput from 'features/common/FormControls/TimeInput';
import AuthorRecipientLabel from '../labels/AuthorRecipientLabel';
import { AddCircleOutline, ExpandLess, ExpandMore } from '@mui/icons-material';
import FileViewer from '../../FileViewer';
import { Modal } from 'features/common/MaterialBasedComponents';

const ModalUpdateMeta = ({
  show,
  handleClose,
  handleSave,
  action,
  selectedRows,
  isAllDocuments,
  currentGridList,
}) => {
  const dispatch = useDispatch();

  const { file: alreadyOpenedFile } = useSelector(selectParams);
  const [file, setFile] = useState(selectedRows[selectedRows.length - 1] || {});
  const prevFile = usePrevious(file);
  const [loading, setLoading] = useState(false);
  const [navigationBtnClicked, setNavigationBtnClicked] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(null);

  const [authorVal, setAuthorVal] = useState(file.author || '');
  const [recipientVal, setRecipientVal] = useState(file.recipient || '');
  const [recipientListVal, setRecipientListVal] = useState(file.recipientsList || []);
  const [newRecipientVal, setNewRecipientVal] = useState('');
  const prevRecipientListVal = usePrevious(recipientListVal);
  const [fileNameVal, setFileNameVal] = useState(file.name || file.originalName || '');
  const [showPreview, setShowPreview] = useState(false);
  const [disableActionBtns, setDisableActionBtns] = useState({
    disableNext: false,
    disablePrev: false,
  });
  const [openEntities, setOpenEntities] = useState(false);

  const {
    documents: { canUpdatePublicData, canUpdateTeamData },
  } = useSelector(selectPermissions);
  const isAutoSetDocDate = useSelector(selectAutoSetDoDate);
  const canChangeDocId = useSelector(selectChangeDocId);
  const isTrialBookAgreed = useSelector(selectCurrentFolderAgreed);
  const currentFolder = useSelector(selectCurrentFolder);
  const totalDocuments = currentGridList.length;
  const { cost, ...entitiesCollection } = (file && file.entities) || null;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    control,
    trigger,
  } = useForm({
    mode: 'onBlur',
    defaultValues: file.docDate
      ? { docDate: file.docDate.split(' ')[0] || '', docTime: file.docDate.split(' ')[1] || '' }
      : { docDate: '', docTime: '' },
    reValidateMode: 'onBlur',
  });

  const selectNewDocument = fileId => {
    currentGridList.forEach(doc => {
      if (doc.id === fileId) doc.selected = true;
      else doc.selected = false;
    });
  };

  const handleUserKeyPress = useCallback(
    e => {
      if (disableActionBtns.disablePrev && disableActionBtns.disableNext) return;
      setNavigationBtnClicked(false);
      if ((e.ctrlKey || e.metaKey) && e.code === 'ArrowRight') {
        setCurrentSelection(e.target.name);
        const nextBtn = document.getElementById('updateFileNextButton');
        nextBtn.click();
        setNavigationBtnClicked(true);
      } else if ((e.ctrlKey || e.metaKey) && e.code === 'ArrowLeft') {
        setCurrentSelection(e.target.name);
        const prevBtn = document.getElementById('updateFilePrevButton');
        prevBtn.click();
        setNavigationBtnClicked(true);
      }
    },
    [disableActionBtns],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    if (alreadyOpenedFile) {
      if (alreadyOpenedFile !== file.id)
        history.push(history.location.pathname.replace(/\/files.*/, ``) + `/files/${file.id}`);
    } else window.location.hash = file.id;
  }, [alreadyOpenedFile, file]);

  useEffect(() => {
    if (file.id !== prevFile.id) {
      setLoading(true);
      dispatch(actions.fetchFileDetails({ fileId: file.id, folderId: currentFolder.id })).then(
        res => {
          reset(
            file.docDate
              ? {
                  docDate: file.docDate.split(' ')[0] || '',
                  docTime: file.docDate.split(' ')[1] || '',
                }
              : { docDate: '', docTime: '' },
          );
          setFile(res);

          setFileNameVal(res.name || res.originalName || '');
          setAuthorVal(res.author || '');
          setRecipientVal(res.recipient || '');
          setRecipientListVal(res.recipientsList || []);
          setLoading(false);
          const currentFileIdx = currentGridList.findIndex(doc => doc.id === file.id);
          setDisableActionBtns(
            currentFileIdx === 0
              ? { disableNext: false, disablePrev: true }
              : currentFileIdx === currentGridList.length - 1
              ? { disableNext: true, disablePrev: false }
              : { disableNext: false, disablePrev: false },
          );
        },
      );
    }
  }, [file, prevFile, dispatch, currentFolder, currentGridList, reset]);

  const prevClicked = data => {
    const currentFileIdx = currentGridList.findIndex(doc => doc.id === file.id);
    if (currentFileIdx > 0) {
      setLoading(true);
      setDisableActionBtns({ disableNext: true, disablePrev: true });
      saveFileData(data, currentGridList[currentFileIdx - 1].id);
      reset({});
      setFile(currentGridList[currentFileIdx - 1]);
      setValue('date', '');
      setValue('time', '');
    }
  };

  const nextClicked = data => {
    const currentFileIdx = currentGridList.findIndex(doc => doc.id === file.id);
    if (currentFileIdx < totalDocuments - 1) {
      setLoading(true);
      setDisableActionBtns({ disableNext: true, disablePrev: true });
      saveFileData(data, currentGridList[currentFileIdx + 1].id);
      reset({});
      setFile(currentGridList[currentFileIdx + 1]);
    }
  };

  const saveFileData = (data, newDocId) => {
    const isDocTime = !!data.docTime;
    const cleanData = {
      ...Object.entries(data).reduce(
        (a, [k, v]) =>
          (v === '' && !file[k]) || (isDocTime && k === 'docTime')
            ? a
            : isDocTime && k === 'docDate'
            ? { ...a, [k]: v + ' ' + data['docTime'] }
            : { ...a, [k]: v },
        {},
      ),
      ...{ etag: file.etag },
    };
    if (isEqual({ ...file, ...cleanData }, file) && newDocId) selectNewDocument(newDocId);
    else handleSave(null, action, [file], cleanData);
  };

  const isValidDate = val => {
    if (val) return moment(val, 'DD/MM/YYYY', true).isValid();
    else return true;
  };

  const isValidTime = val => {
    if (val) return moment(val, 'HH:mm:ss', true).isValid();
    else return true;
  };

  const areAllGroupsEmpty = groups => {
    return Object.values(groups).every(group => group.length === 0);
  };

  const handleAddRecipient = () => {
    if (newRecipientVal) {
      setRecipientListVal([...recipientListVal, newRecipientVal]);
      setNewRecipientVal('');
    }
  };

  const setRecipientsList = suggestedRecipientsOption => {
    if (suggestedRecipientsOption === suggestedRecipientsMenuOptions.addAll) {
      const newRecipients = [
        ...new Set([...recipientListVal, ...(file.suggestedRecipients || [])]),
      ];
      setLoading(true);
      setRecipientListVal(newRecipients);
      setValue('recipientsList', newRecipients);
      setTimeout(() => setLoading(false), 0);
    } else {
      setLoading(true);
      setRecipientListVal([]);
      setValue('recipientsList', []);
      setTimeout(() => setLoading(false), 0);
    }
    setRecipientVal(suggestedRecipientsOption);
  };

  const allowOnlyNumbersAndDots = event => {
    const allowedKeys = [
      '.',
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
    ];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Modal
      show={show}
      title={T.translate('case.updateMetadata') + ' - ' + file.id}
      wide={showPreview}
      handleClose={() => {
        handleClose();
        setNavigationBtnClicked(false);
        setCurrentSelection(null);
      }}
      handleSave={handleSubmit(data => {
        saveFileData(data);
        handleClose();
        setNavigationBtnClicked(false);
        setCurrentSelection(null);
      })}
      saveTitle={T.translate('generic.update')}
      buttons={
        <>
          <Button
            type="button"
            id="updateFilePrevButton"
            onClick={handleSubmit(data => prevClicked(data))}
            variant="contained"
            color="primary"
            disabled={disableActionBtns.disablePrev}
          >
            {T.translate('generic.previous')}
          </Button>
          <Button
            type="button"
            id="updateFileNextButton"
            onClick={handleSubmit(data => nextClicked(data))}
            variant="contained"
            color="primary"
            disabled={disableActionBtns.disableNext}
          >
            {T.translate('generic.next')}
          </Button>
        </>
      }
      hintContent={
        <>
          <span>
            <b>{T.translate('generic.shortcuts')}</b>
          </span>
          <Divider />
          <span>{T.translate('common.shortcutPrevText')}</span>
          <br />
          <span>{T.translate('common.shortcutNextText')}</span>
          <br />
          <span>{T.translate('common.shortcutSaveText')}</span>
        </>
      }
      draggableDialog
      fullHeight={showPreview}
    >
      {loading ? (
        <Spinner />
      ) : (
        <Split
          sizes={showPreview ? [45, 55] : [100, 0]}
          minSize={showPreview ? 100 : 0}
          expandToMin={false}
          gutterSize={10}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
          className={classnames({ 'no-gutter': !showPreview }, 'case-table-and-viewer')}
        >
          <Form
            onSubmit={e => {
              e.preventDefault();
            }}
            style={{ ...(showPreview && { marginRight: '0.5rem' }) }}
          >
            <Form.Group style={{ margin: '0.5rem' }}>
              {canUpdatePublicData(file) && (
                <>
                  <Row style={{ marginBottom: 0 }}>
                    <Col>
                      {file.suggestedSubject && (
                        <SubjectLabel
                          isAuthor={false}
                          data={file.suggestedSubject}
                          setValue={setValue}
                          setSelectedVal={setFileNameVal}
                          value={fileNameVal}
                        />
                      )}
                    </Col>
                    <Col />
                  </Row>
                  <Row>
                    <Col>
                      {!(canChangeDocId && isAllDocuments) && (
                        <Button
                          sx={{ float: 'right', p: 0 }}
                          variant="text"
                          onClick={() => {
                            setLoading(true);
                            setShowPreview(!showPreview);
                            setTimeout(() => {
                              setLoading(false);
                            }, 0);
                            setShowPreview(!showPreview);
                          }}
                        >
                          {showPreview ? 'Hide' : 'Preview'}
                        </Button>
                      )}
                      <TextForm
                        {...register('name', {
                          required: true,
                          onChange: e => setFileNameVal(e.target.value),
                        })}
                        label={T.translate('case.systemName')}
                        placeholder={T.translate('case.namePlaceholder')}
                        value={fileNameVal}
                        autofocus={
                          !currentSelection || (navigationBtnClicked && currentSelection === 'name')
                        }
                        errors={errors}
                      />
                    </Col>
                    {canChangeDocId && isAllDocuments && (
                      <Col>
                        <Button
                          sx={{ float: 'right', p: 0 }}
                          variant="text"
                          onClick={() => {
                            setLoading(true);
                            setShowPreview(!showPreview);
                            setTimeout(() => {
                              setLoading(false);
                            }, 0);
                            setShowPreview(!showPreview);
                          }}
                        >
                          {showPreview ? 'Hide' : 'Preview'}
                        </Button>
                        <TextForm
                          {...register('id', { required: true })}
                          label={T.translate('case.documentId')}
                          placeholder={T.translate('case.documentIdPlaceholder')}
                          defaultValue={file.id}
                          errors={errors}
                        />
                      </Col>
                    )}
                  </Row>
                  {isTrialBookAgreed && (
                    <Row>
                      <Col>
                        <TextForm
                          {...register('tab', {
                            validate: {
                              isValidNumber: val => {
                                if (!val) return true;
                                const pattern = /^\d+(\.\d+)*$/;
                                return (
                                  pattern.test(val) ||
                                  'Invalid format. Only digits and (optional) multiple decimal segments allowed.'
                                );
                              },
                            },
                          })}
                          onKeyDown={allowOnlyNumbersAndDots}
                          label={T.translate('case.tab')}
                          placeholder={T.translate('case.tabPlaceholder')}
                          defaultValue={file.tab}
                          readOnly={file.agreed}
                          errors={errors}
                        />
                      </Col>
                      {currentFolder && currentFolder.globalPaging ? (
                        <Col>
                          <TextForm
                            {...register('startPage', {
                              validate: { isValidNumber: val => val >= 0 },
                            })}
                            label={T.translate('case.startPage')}
                            type="number"
                            placeholder={T.translate('case.startPagePlaceholder')}
                            defaultValue={file.startPage}
                            readOnly={file.agreed}
                            errors={errors}
                          />
                        </Col>
                      ) : (
                        <Col />
                      )}
                    </Row>
                  )}
                  <Row style={{ marginBottom: 0 }}>
                    <Col>
                      <DateFormLabel
                        isAutoSetDocDate={isAutoSetDocDate}
                        dates={file.docDates}
                        trigger={trigger}
                        setValue={setValue}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Controller
                        name={'docDate'}
                        control={control}
                        rules={{
                          validate: {
                            isValidDate: val => isValidDate(val),
                          },
                          required: !!getValues('time') && T.translate('case.dateRequiredMsg'),
                        }}
                        render={({ field }) => (
                          <DateInput
                            {...field}
                            label={T.translate('case.dateWithFormat')}
                            placeholder={T.translate('case.datePlaceholder')}
                            errors={errors}
                            autofocus={navigationBtnClicked && currentSelection === 'docDate'}
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <Controller
                        name={'docTime'}
                        control={control}
                        rules={{
                          validate: {
                            isValidTime: val => isValidTime(val),
                          },
                        }}
                        render={({ field }) => (
                          <TimeInput
                            {...field}
                            label={T.translate('case.time')}
                            placeholder={T.translate('case.timePlaceholder')}
                            errors={errors}
                            autofocus={navigationBtnClicked && currentSelection === 'docTime'}
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <TextForm
                        {...register('altDate')}
                        label={T.translate('case.alternativeDate')}
                        placeholder={T.translate('case.alternativeDatePlaceholder')}
                        defaultValue={file.altDate}
                        autofocus={navigationBtnClicked && currentSelection === 'altDate'}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextForm
                        {...register('discoveryId')}
                        label={T.translate('case.discoveryId')}
                        placeholder={T.translate('case.discoveryIdPlaceholder')}
                        defaultValue={file.discoveryId}
                        autofocus={navigationBtnClicked && currentSelection === 'discoveryId'}
                      />
                    </Col>
                    <Col>
                      <TextForm
                        {...register('externalId')}
                        label={T.translate('case.externalId')}
                        placeholder={T.translate('case.externalIdPlaceholder')}
                        defaultValue={file.externalId}
                        autofocus={navigationBtnClicked && currentSelection === 'externalId'}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: 0 }}>
                    <Col>
                      {file.suggestedAuthors && file.suggestedAuthors.length > 0 && (
                        <AuthorRecipientLabel
                          isAuthor={true}
                          data={file.suggestedAuthors}
                          setValue={setValue}
                          setSelectedVal={setAuthorVal}
                          value={authorVal}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextForm
                        {...register('author', { onChange: e => setAuthorVal(e.target.value) })}
                        label={T.translate('case.author')}
                        placeholder={T.translate('case.authorPlaceholder')}
                        value={authorVal}
                        autofocus={navigationBtnClicked && currentSelection === 'author'}
                      />
                    </Col>
                    <Col />
                  </Row>
                  <Row style={{ marginBottom: 0 }}>
                    <Col>
                      {file.suggestedRecipients && file.suggestedRecipients.length > 0 && (
                        <AuthorRecipientLabel
                          isAuthor={false}
                          data={file.suggestedRecipients}
                          setValue={setValue}
                          setSelectedVal={setRecipientVal}
                          value={recipientVal}
                          setRecipientsList={setRecipientsList}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginBottom: '0.5rem',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>{T.translate('case.recipient')}</span>
                        <TextField
                          variant="standard"
                          size="small"
                          autoComplete="off"
                          placeholder={T.translate('case.newRecipientPlaceholder')}
                          value={newRecipientVal}
                          onChange={e => setNewRecipientVal(e.target.value)}
                          onKeyDown={e => {
                            if (e.key === 'Enter' && newRecipientVal.length > 0) {
                              e.preventDefault();
                              e.stopPropagation();
                              handleAddRecipient();
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleAddRecipient}
                                  edge="end"
                                  disabled={!newRecipientVal}
                                >
                                  {
                                    <AddCircleOutline
                                      color={!newRecipientVal ? 'disabled' : 'primary'}
                                    />
                                  }
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <MultiSelectForm
                        name="recipientsList"
                        defaultValue={recipientListVal}
                        options={recipientListVal.map(val => ({
                          label: val,
                          value: val,
                        }))}
                        control={control}
                        error={errors}
                        autofocus={recipientListVal.length !== prevRecipientListVal?.length}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextForm
                        {...register('description')}
                        label={T.translate('case.description')}
                        multiline
                        placeholder={T.translate('case.descriptionPlaceholder')}
                        defaultValue={file.description}
                        autofocus={navigationBtnClicked && currentSelection === 'description'}
                      />
                    </Col>
                  </Row>
                </>
              )}
              {canUpdateTeamData(file) && (
                <Row>
                  <Col>
                    <TextForm
                      {...register('notes')}
                      multiline
                      label={`${T.translate('case.notes')} (${T.translate('case.privateToTeam')})`}
                      placeholder={T.translate('case.notesPlaceholder')}
                      defaultValue={file.notes}
                      autofocus={navigationBtnClicked && currentSelection === 'notes'}
                    />
                  </Col>
                </Row>
              )}
              {entitiesCollection && !areAllGroupsEmpty(entitiesCollection) && (
                <>
                  <Row>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ width: '100%' }}>{T.translate('generic.entities')}</span>
                      <IconButton onClick={() => setOpenEntities(!openEntities)}>
                        {openEntities ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </Col>
                  </Row>
                  <Collapse in={openEntities} timeout="auto" unmountOnExit>
                    {Object.entries(entitiesCollection).map(
                      ([key, value]) =>
                        value &&
                        value.length > 0 && (
                          <Row key={key}>
                            <Col>
                              <MultiSelectForm
                                label={key}
                                name={key}
                                defaultValue={value}
                                options={value.map(val => ({
                                  label: val,
                                  value: val,
                                  isFixed: true,
                                }))}
                                readOnly
                                control={control}
                                error={errors}
                              />
                            </Col>
                          </Row>
                        ),
                    )}
                  </Collapse>
                </>
              )}
            </Form.Group>
          </Form>
          {showPreview && (
            <div>
              <FileViewer fileId={file.id} isPreviewMode />
            </div>
          )}
        </Split>
      )}
    </Modal>
  );
};

export default ModalUpdateMeta;
