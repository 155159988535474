import React, { useState, memo } from 'react';
import { IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { CopyAllRounded, Delete } from '@mui/icons-material';

type HyperlinkCandidate = any;

function shortenString(str: string | undefined): string {
  if (!str) return '';
  if (str.length > 75) {
    return `${str.substring(0, 30)}...${str.substring(str.length - 30)}`;
  }
  return str;
}

type HyperlinkRowProps = {
  link: HyperlinkCandidate;
  classes?: any;
  handleDeleteRow?: (link: HyperlinkCandidate) => void;
  index?: number;
  isEditing?: boolean;
};

function HyperlinkRowComponent({
  link,
  classes,
  handleDeleteRow,
  index,
  isEditing,
}: HyperlinkRowProps) {
  const [copyFeedback, setCopyFeedback] = useState<string | null>(null);

  const handleCopyText = async () => {
    if (!navigator.clipboard?.writeText) {
      setCopyFeedback('Copy not supported');
      return;
    }

    try {
      await navigator.clipboard.writeText(window.location.origin + (link.href || ''));
      setCopyFeedback('Copied!');
      setTimeout(() => setCopyFeedback(null), 2000);
    } catch (err) {
      setCopyFeedback('Failed to copy');
      setTimeout(() => setCopyFeedback(null), 2000);
    }
  };

  return (
    <tr>
      {isEditing && (
        <td
          className={classes.td}
          style={{ width: '40px', textAlign: 'center', verticalAlign: 'middle' }}
        >
          <IconButton
            size="small"
            onClick={() => handleDeleteRow && handleDeleteRow(index as number)}
            aria-label="Delete hyperlink row"
          >
            <Delete fontSize="small" color="error" />
          </IconButton>
        </td>
      )}

      <td className={classes.td}>{link.sourcePage}</td>
      <td className={classes.td}>{link.sourceText}</td>
      <td className={classes.td}>{link.targetDocId}</td>
      <td className={classes.td}>{link.targetPage}</td>
      <td className={classes.td} style={{ whiteSpace: 'nowrap' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link to={link.href || '#'} style={{ color: '#748CEC' }} target="_blank">
            {shortenString(link.targetFilename)}
          </Link>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }}>
            {copyFeedback === 'Copied!' && (
              <Typography variant="body2" style={{ color: '#60D362', marginRight: 4 }}>
                {copyFeedback}
              </Typography>
            )}
            <IconButton size="small" onClick={handleCopyText} title="Copy hyperlink">
              <CopyAllRounded fontSize="small" />
            </IconButton>
          </div>
        </div>
      </td>
    </tr>
  );
}

export const HyperlinkRow = memo(HyperlinkRowComponent);
