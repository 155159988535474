import { Collapsible, InfoRow } from 'features/common';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import T from 'i18n';
import { selectFolders } from 'common/selectors';
import FilterProvider from '../../TableCase/FilterProvider';
import { FileDetails } from '../../FileDetails';
import { InfoRowBoolean } from 'features/common/InfoRow';
import * as actions from '../../redux/actions';
import { Modal } from 'features/common/MaterialBasedComponents';

const MoreInfoModal = ({ show, handleClose, selectedRows, filter }) => {
  const dispatch = useDispatch();
  const folders = useSelector(selectFolders);
  const foldersList = Object.values(folders).reduce((acc, list) => {
    return [...acc, ...list];
  }, []);
  const [mergeDetails, setMergeDetails] = useState(null);
  const {
    mergedFileName = '',
    generateIndexPage,
    generateBookMarks,
    generateLocalPaging,
    generateGlobalPaging,
    coreBundleUseTrialBundlePaging,
    useDoubleSidedPrinting,
    burnCrossrefsFirst,
    globalPagingPrefix,
    folderToMerge,
  } = mergeDetails || {};
  const mergedFolderName = folderToMerge
    ? foldersList.find(folder => folder.id === folderToMerge)?.name
    : '';

  useEffect(() => {
    if (selectedRows[0].source === 'merge') {
      dispatch(actions.fetchDocumentMergeDetails({ fileId: selectedRows[0].id })).then(res =>
        setMergeDetails(res),
      );
    }
  }, [selectedRows, dispatch]);

  return (
    <>
      <Modal
        show={show}
        title={T.translate('generic.more')}
        handleClose={() => {
          handleClose();
        }}
      >
        <FilterProvider value={{ filter }}>
          <FileDetails row={selectedRows[0]} columns={[]} DefaultShowMoreInfo={true} />
          {mergeDetails && (
            <div style={{ margin: '1rem' }}>
              <Collapsible title={T.translate('case.mergeDetails')}>
                <InfoRow field={mergedFileName} title={T.translate('case.mergedFileName')} />
                <InfoRowBoolean
                  field={generateIndexPage}
                  title={T.translate(`case.generateIndexPage`)}
                />
                <InfoRowBoolean
                  field={generateBookMarks}
                  title={T.translate(`case.generateBookMarks`)}
                />
                <InfoRowBoolean
                  field={generateLocalPaging}
                  title={T.translate(`case.generateLocalPaging`)}
                />
                <InfoRowBoolean
                  field={generateGlobalPaging}
                  title={T.translate(`case.generateGlobalPaging`)}
                />
                <InfoRowBoolean
                  field={coreBundleUseTrialBundlePaging}
                  title={T.translate(`case.coreBundleUseTrialBundlePaging`)}
                />
                <InfoRowBoolean
                  field={useDoubleSidedPrinting}
                  title={T.translate(`case.useDoubleSidedPrinting`)}
                />
                <InfoRowBoolean
                  field={burnCrossrefsFirst}
                  title={T.translate(`case.includeCrossRef`)}
                />

                <InfoRow field={globalPagingPrefix} title={T.translate(`case.globalPagePrefix`)} />
                <InfoRow field={mergedFolderName} title={T.translate(`case.mergedFolderName`)} />
              </Collapsible>
            </div>
          )}
        </FilterProvider>
      </Modal>
    </>
  );
};

export default MoreInfoModal;
